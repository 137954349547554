import React from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';

const Component = ({ blok, className }) => (
  <SbEditable content={blok}>
    <div className={cx('relative transition ease-in-out duration-700 ', className, {
      'downloadable-item': blok.file_url.filename,
    })}
    >
      <a
        href={blok.file_url.filename}
        download={blok.file_url.filename}
        target="_blank"
        rel="noreferrer"
        className={cx('absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-xl font-gilroyBold lg:text-4xl', {
          'hover:bg-light-green': blok.file_url.filename,
          'hover:shadow-xl': blok.file_url.filename,
        })}
      >
        {blok.file_name}
      </a>
    </div>
  </SbEditable>
);

export default Component;
