import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import {
  Accordion,
  AccordionItem,
  resetNextUuid,
} from 'react-accessible-accordion';
import Component from '../index';

const ComponentContainer = ({ blok }) => {
  resetNextUuid();
  const firstItem = [blok?.accordion_items[0]._uid];
  const [sectionId, setSectionId] = useState(firstItem);

  return (
    <SbEditable content={blok}>
      <figure className="container grid grid-cols-1 py-12 pt-0 lg:grid-cols-8 lg:pb-12">
        <div className="col-span-1 py-12 pt-0 bg-gray-200 lg:p-0 lg:pb-12 lg:col-span-6 ">
          <div
            className="h-full mb-12 bg-no-repeat bg-cover border-2 lg:-ml-12 lg:mb-0 sm-square"
            style={{ backgroundImage: `url(${sectionId && blok.accordion_items.filter((item) => item._uid === sectionId[0]).map(({ image }) => image.filename)})` }}
          />
        </div>
        <figcaption className="col-span-2 pb-12 bg-gray-200">
          <Accordion preExpanded={firstItem} onChange={(props) => setSectionId(props)} className="accordion-main">
            {blok.accordion_items.map((blk) => (
              <AccordionItem key={blk._uid} uuid={blk._uid} className="mx-12 border-t border-blue-700 last:border-b">
                <Component blok={blk} />
              </AccordionItem>
            ))}
          </Accordion>
        </figcaption>
      </figure>
    </SbEditable>
  );
};

export default ComponentContainer;
