import React from 'react';
import SbEditable from 'storyblok-react';

const JumbotronVideo = ({ blok }) => (
  <SbEditable content={blok}>
    <section className="bg-no-repeat bg-cover pt-32 xl:pt-64" style={{ backgroundImage: `url(${blok.background?.filename}) `}}>
      <header className="container text-white pb-16 xl:pb-32 pl-4 border-l-2 border-l-white">
        <h2 className="text-4xl lg:text-7xl font-bold">{blok.heading}</h2>
        <p className="text-4xl lg:text-7xl font-regular">{blok.lead}</p>
      </header>
    </section>
  </SbEditable>
);

export default JumbotronVideo;
