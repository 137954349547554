import React from 'react';
import SbEditable from 'storyblok-react';
import Component from '../index';

const Masonry = ({ blok }) => (
  <SbEditable content={blok}>
    <div className="p-1 align-top">
      <img alt={blok.image.alt} className="w-full max-w-full align-middle" src={blok.image.filename} />
    </div>
  </SbEditable>
);

export default Masonry;
