import SbEditable from 'storyblok-react';
import Component from '../index';

const GridPartner = ({ blok }) => (
  <SbEditable content={blok}>
    <section className='container bg-accent-1 py-16 lg:px-20' id={blok.section_id}>
        <h2 className='text-blue-700 font-semiBold text-4xl lg:text-6xl leading-nonde'>{blok.title}</h2>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-4 items-stretch auto-rows-fr pt-10'>
            {blok.partner_cards.map((blk) => <Component blok={blk} key={blk._uid} />)}
        </div>      
    </section>
  </SbEditable>
);

export default GridPartner;
