import React, { useState } from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';
import Component from '../index';
import EmptyTeamGridItem from './EmptyTeamGridItem';

const DEFAULT_LIMIT = 12;
const LOAD_MORE = 8;

const Grid = ({ blok }) => {
  const [occupationFilter, setOccupationFilter] = useState([1, 2, 3, 4]);
  const [nameFilter, setNameFilter] = useState(null);
  const [itemsLimit, setItemsLimit] = useState(DEFAULT_LIMIT);

  const filteredItems = blok.items.filter(item =>
    occupationFilter.includes(Number(item.occupation))
  );

  const toggleOccupationFilter = value => {
    if (occupationFilter.includes(value)) {
      const filteredOccupationFilter = occupationFilter.filter(
        val => val !== value
      );
      setOccupationFilter(filteredOccupationFilter);
    } else {
      setOccupationFilter([value, ...occupationFilter]);
    }
  };

  const handleChange = e => {
    setNameFilter(e.target.value);
    setOccupationFilter([1, 2, 3, 4]);
  };

  const lowercasedFilter = nameFilter?.toLowerCase();

  const occupationFilterMap = {
    1: "Vorstand",
    2: "Geschäftsstelle",
    3: "Hauswarte",
    4: "Malerteam",
  }

  const data = nameFilter
    ? filteredItems.filter(item =>
        Object.keys(item).some(
          key =>
            typeof item[key] === 'string' &&
            item[key].toLowerCase().includes(lowercasedFilter)
        )
      )
    : filteredItems;

  const handleShowMore = () => {
    setItemsLimit(prevLimit => prevLimit + LOAD_MORE);
  };

  const onClick1 = value => {
    if (occupationFilter.length === 1 && occupationFilter[0] === value) {
      setOccupationFilter([1, 2, 3, 4]);
    } else {
      setOccupationFilter([value]);
    }
  };

  const shouldShowMore = data.length > DEFAULT_LIMIT && data.length > itemsLimit

  return (
    <SbEditable content={blok}>
      <div className="container">
        <div className="flex flex-col justify-between p-8 bg-gray-100 lg:flex-row">
          <div>
            <label htmlFor="searchInput" className="hidden">
              Search input
            </label>
            <input
              id="searchInput"
              type="text"
              value={nameFilter}
              onChange={handleChange}
              placeholder="Name"
              className="px-4 py-2"
            />
          </div>
          <div className="pt-4 lg:pt-0">
            <button
              type="button"
              onClick={() => onClick1(1)}
              className={cx('text-2.5xl px-4 pl-0 lg:pl-4 focus:outline-none', {
                'font-semiBold': occupationFilter.includes(1),
              })}
            >
              Vorstand
            </button>
            <button
              type="button"
              onClick={() => onClick1(2)}
              className={cx('text-2.5xl px-4 pl-0 lg:pl-4 focus:outline-none', {
                'font-semiBold': occupationFilter.includes(2),
              })}
            >
              Geschäftsstelle
            </button>
            <button
              type="button"
              onClick={() => onClick1(3)}
              className={cx('text-2.5xl px-4 pl-0 lg:pl-4 focus:outline-none', {
                'font-semiBold': occupationFilter.includes(3),
              })}
            >
              Hauswarte
            </button>
            <button
              type="button"
              onClick={() => onClick1(4)}
              className={cx('text-2.5xl px-4 pl-0 lg:pl-4 focus:outline-none', {
                'font-semiBold': occupationFilter.includes(4),
              })}
            >
              Malerteam
            </button>
          </div>
        </div>

        <section className="grid grid-flow-row grid-cols-1 gap-0 pb-16 md:grid-cols-2 lg:grid-cols-4">
          {data.slice(0, itemsLimit).map(blk => (
            <Component blok={blk} key={blk._uid} />
          ))}
          {!shouldShowMore && !data?.length &&  (
            <EmptyTeamGridItem blok={{
              occupation: occupationFilter[0].toString() ?? "1",
              text: occupationFilterMap[occupationFilter[0]] || "Team"
            }} />
          )}
        </section>
        <div className="flex justify-center">
          {shouldShowMore && (
            <button
              className="inline-flex items-center px-6 py-4 text-xl text-white bg-blue-700 hover:bg-blue-200 font-regular focus:outline-none"
              type="button"
              onClick={() => handleShowMore()}
            >
              <span>Mehr anzeigen</span>
              <svg viewBox="0 0 64 64" className="inline-block w-10 pl-4">
                <path
                  className="fill-current"
                  d="M44.2,53.2c-0.5-0.6-1.5-0.7-2.1-0.2l-8.6,7.2V1.5C33.5,0.7,32.8,0,32,0c-0.8,0-1.5,0.7-1.5,1.5v58.7L21.9,53	c-0.6-0.5-1.6-0.5-2.1,0.2c-0.5,0.6-0.5,1.6,0.2,2.1l9.1,7.6C29.9,63.6,31,64,32,64c1,0,2.1-0.4,2.9-1.1l9.1-7.6	C44.7,54.8,44.8,53.8,44.2,53.2z"
                />{' '}
                :
              </svg>
            </button>
          )}
        </div>
      </div>
    </SbEditable>
  );
};

export default Grid;
