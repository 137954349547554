import Image from "next/image";
import Link from "next/link";
import { NODE_HEADING, NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer";
import RichTextRaw from "./RichTextRaw";
import { BsArrowDown } from "react-icons/bs";
import { extractDimensionsFromFilename } from "utils/img-helpers";

const CardIconText = ({blok}) => {
    const {width, height} = extractDimensionsFromFilename(blok.icon.filename)

    const Icon = () => (
        <div className="w-full max-w-4">
            <Image
                src={blok.icon.filename}
                alt={blok.icon.alt}
                layout="responsive"
                objectFit="contain"
                width={width}
                height={height}
            />
        </div>
    ) 

    const Text = () => (
        <RichTextRaw 
            text={blok.text}
            nodeResolvers={{
                [NODE_PARAGRAPH]: (children) => (
                    <p className="font-regular text-base text-blue-700 mt-10">{children}</p>
                ),
                [NODE_HEADING]: (children) => (
                    <h3 className="font-semiBold text-blue-700 text-lg leading-none mt-5">{children}</h3>
                ),
            }}
        />
    )
    return (
        <>
            {blok.link.cached_url ? (
            <Link href={blok.link.cached_url === 'startseite/' ? '/' : blok.link.cached_url}>
                <a className="bg-blue-50 p-8 flex flex-col justify-between group">
                    <Icon/>
                    <div className="flex justify-between items-end text-blue-700 text-2xl">
                        <div>
                        <Text/>
                        </div>
                        <BsArrowDown className="flex-shrink-0 group-hover:animate-bounce"/>
                    </div>
                </a>
            </Link>
        ) : (
           <div className="border border-blue-50 p-8 flex flex-col justify-between">
                <Icon/>
                <div>
                    <Text/>
                </div>
            </div>
        )}
        </>
    )
}

export default CardIconText
