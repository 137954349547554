import React from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';
import Component from '../index';

const Container = ({ blok }) => (
  <SbEditable content={blok}>
    <section className={cx(`container grid grid-cols-1 relative lg:grid-cols-${blok.noOfColumns || blok.columns.length}`, blok.className)} id={blok.id}>
      {blok.columns.map((blk) => <Component blok={blk} key={blk._uid} />)}
    </section>
  </SbEditable>
);

export default Container;
