import React from 'react';
import {
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import SbEditable from 'storyblok-react';

const IconUp = () => (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612" className="w-4 h-4 fill-current"><path d="M7.5,477.2c10,10.1,26.2,10.1,36.2,0L306,189.4l262.3,287.8c10,10.1,26.2,10.1,36.2,0c10-10,10-26.4,0-36.4L325.4,134.6 c-5.3-5.4-12.4-7.7-19.4-7.3c-7-0.4-14.1,1.9-19.4,7.3L7.5,440.8C-2.5,450.9-2.5,467.2,7.5,477.2z" /></svg>);
const IconDown = () => (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612" className="w-4 h-4 fill-current"><path d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782 c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296 c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z" /></svg>);

const AccordionSection = ({ blok }) => (
  <SbEditable content={blok}>
    <AccordionItemHeading>
      <AccordionItemButton className="outline-none">
        <AccordionItemState>
          {({ expanded }) => (expanded
            ? (
              <h2 className="relative flex justify-between py-3 pl-10 text-base text-blue-700 uppercase lg:py-4 xl:text-xl accordion-main-counter font-regular">
                <span>{blok.heading}</span>
                <IconUp />
              </h2>
            )
            : (
              <h2 className="relative flex justify-between py-3 pl-10 text-base text-blue-700 uppercase lg:py-4 xl:text-xl accordion-main-counter font-regular">
                <span>{blok.heading}</span>
                <IconDown />
              </h2>
            ))}
        </AccordionItemState>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <p className="pb-4 pl-10 text-sm">{blok.content}</p>
    </AccordionItemPanel>
  </SbEditable>
);

export default AccordionSection;
