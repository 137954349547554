import React from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';

const Teaser2 = ({ blok }) => (
  <SbEditable content={blok}>
    <p className={cx("text-lg leading-relaxed lg:text-justify", blok.className)}>{blok.content}</p>
  </SbEditable>
);

export default Teaser2;
