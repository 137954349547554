import React from 'react';
import SbEditable from 'storyblok-react';
import Component from '../index';
import { ReactComponent as Icon } from '../../public/icons/Icon_PDF.svg';

const Masonry = ({ blok }) => (
  <SbEditable content={blok}>
    <section className="container grid grid-cols-4 gap-2 text-center downloads-masonry lg:auto-rows-fr lg:grid-cols-8">
      <div className="flex items-center justify-center col-span-4 row-span-2 row-start-1 row-end-3 text-2xl font-semiBold bg-light-olive-green lg:col-start-0 lg:col-end-3 lg:col-span-2">
        <div className="flex flex-col items-center">
          {/* <Icon className="w-20 h-20 m-4" /> */}
          <span>Jahresberichte</span>
        </div>
      </div>
      {blok?.annual_reports.map(blk => (
        <Component blok={blk} key={blk._uid} className="bg-light-olive-green" />
      ))}
      <div className="grid grid-cols-4 col-span-4 gap-2 lg:col-span-6 lg:row-span-3 historic-reports lg:grid-cols-6 lg:grid-rows-3 lg:col-start-0 lg:col-end-7 lg:row-start-3">
        <div className="flex items-center justify-center col-span-4 row-start-1 row-end-3 text-2xl bg-gray-100 lg:col-start-5 lg:col-end-7 gesichte font-semiBold">
          <div className="flex flex-col items-center">
            {/* <Icon className="w-20 h-20 m-4" /> */}
            <span>Geschichte</span>
          </div>
        </div>
        {blok?.historical_reports.map(blk => (
          <Component
            blok={blk}
            key={blk._uid}
            className="col-span-2 bg-gray-100 historic-report rectangle"
          />
        ))}
      </div>
      {blok?.statute && (
        <div className="relative flex items-center justify-center col-span-4 row-span-2 text-2xl transition duration-700 ease-in-out downloadable-item bg-light-olive-green font-semiBold lg:col-span-2 hover:bg-light-green hover:shadow-xl">
          <a
            className="flex flex-col items-center"
            href={blok.statute.filename}
            rel="noreferrer"
            target="_blank"
          >
            {/* <Icon className="w-20 h-20 m-4" /> */}
            <span>Statuten</span>
          </a>
        </div>
      )}
    </section>
  </SbEditable>
);

export default Masonry;
