import { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { HiArrowRight } from 'react-icons/hi';
import { HiChevronDown } from "react-icons/hi2";

const ProjectProgress = ({ blok }) => {
    const [activeStep, setActiveStep] = useState(null);
    const [isIntervalRunning, setIsIntervalRunning] = useState(false);
    const intervalRef = useRef(null);
    const intervalTime = Math.floor(blok.interval_time)*1000

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const mediaQuery = window.matchMedia('(min-width: 768px)');

            const handleMediaQueryChange = (event) => {
                if (event.matches) {
                    setIsIntervalRunning(true);
                    setActiveStep(0);
                } else {
                    setIsIntervalRunning(false);
                    setActiveStep(null);
                }
            };

            if (mediaQuery.matches) {
                setIsIntervalRunning(true);
                setActiveStep(0);
            }

            mediaQuery.addEventListener('change', handleMediaQueryChange);

            return () => {
                mediaQuery.removeEventListener('change', handleMediaQueryChange);
            };
        }
    }, []);

    useEffect(() => {
        if (isIntervalRunning) {
            intervalRef.current = setInterval(() => {
                setActiveStep(prevStep => (prevStep + 1) % blok.project_steps.length);
            }, intervalTime);
        } else {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntervalRunning, blok.project_steps.length]);

    const handleMouseEnter = () => {
        if (isIntervalRunning) {
            clearInterval(intervalRef.current);
        }
    };

    const handleMouseLeave = () => {
        if (isIntervalRunning) {
            intervalRef.current = setInterval(() => {
                setActiveStep(prevStep => (prevStep + 1) % blok.project_steps.length);
            }, intervalTime);
        }
    };

    return (
        <div className="bg-gray-200 p-8">
            <h2 className="text-blue-700 font-semiBold text-3xl leading-none">{blok.title}</h2>
            <div 
                className="grid grid-cols-1 md:grid-cols-3 gap-7 xl:gap-15"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}                            
            >
                <div>
                    {blok.project_steps.map((step, index) => (
                        <ProjectStep 
                            key={step._uid}
                            step={step}
                            isLast={index === blok.project_steps.length - 1}
                            activeStep={activeStep}
                            index={index}
                            setActiveStep={(val) => setActiveStep(val)}
                        />
                    ))}
                </div>
                <div className="relative hidden md:block w-full h-0 pb-full md:col-span-2">
                    {activeStep !== null && (
                        <div className='absolute w-full h-full'>
                            <Image
                                src={blok.project_steps[activeStep].image.filename}
                                alt={blok.project_steps[activeStep].image.alt}
                                layout='fill'
                                objectFit='cover'
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectProgress;

const ProjectStep = ({ step, isLast, index, activeStep, setActiveStep }) => {
    // Create a new Date object
    const date = new Date(step.date);
    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    // Format the date as dd.mm.yyyy
    const formattedDate = `${day}.${month}.${year}`;

    const [isMdOrLarger, setIsMdOrLarger] = useState(null);

    const handleResize = () => {
        setIsMdOrLarger(window.innerWidth >= 768);
    };

    useEffect(() => {
        if(!window) return
        handleResize() 
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = (index) => {
        if (isMdOrLarger) {
            setActiveStep(index);
        } else {
            setActiveStep(index === activeStep ? null : index);
        }
    };


    return (
        <button 
            onClick={() => handleClick(index)}
            className={classNames("relative w-full text-left grid grid-cols-1 gap-3 py-5 font-gilroyMedium leading-none", {
                'border-b': !isLast
            })}
        >
            <div className="flex gap-1 text-xs text-white uppercase">
                <div className="bg-blue-date p-1.5">{formattedDate}</div>
                {step.status === 'done' && <div className="bg-green-status p-1.5">Erledigt</div>}
                {step.status === 'ongoing' && <div className="bg-orange-status p-1.5">Im Gange</div>}
                {step.status === 'pending' && <div className="bg-brown-status p-1.5">Ausstehend</div>}
            </div>
            <div className='flex items-center gap-2.5'>
                <h3 className="text-lg">{step.title}</h3>
                <HiChevronDown className='md:hidden'/>
            </div>
            <div className={classNames("md:hidden relative w-full pb-full", {
                'hidden': activeStep !== index, 
                'block': activeStep === index
            })}>
                <Image
                    src={step.image.filename}
                    alt={step.image.alt}
                    layout='fill'
                    objectFit='cover'
                    className='absolute w-full h-full'
                />
            </div>
            <div className={classNames("absolute inset-0 bg-gray-200 opacity-50 z-10", {
                'hidden md:block': activeStep !== index,
                'hidden': activeStep === index
            })}/>
            {activeStep === index && <HiArrowRight className="hidden md:block absolute right-0 top-1/2"/>}
        </button>
    );
};
