import React from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';

const Image = ({ blok, onClick }) => {
  const desktopHeight = blok?.image?.filename
    ? blok?.image?.filename.split('/')[5].split('x')[1]
    : 0;
  return (
    <SbEditable content={blok}>
      {onClick ? (
        <button
          className={cx('flex justify-center items-center', blok.className)}
          onClick={() => onClick(blok._uid)}
          type="button"
        >
          <div
            style={{
              backgroundImage: `url(${blok.image.filename})`,
              height: `${desktopHeight}px` || '100%',
            }}
            className="w-full bg-center bg-no-repeat bg-contain"
          />
        </button>
      ) : (
        <div className={cx('flex justify-center items-center', blok.className)}>
          {/* <div
            style={{
              backgroundImage: `url(${blok.image.filename})`,
              height: `${desktopHeight * 0.6}px` || '100%',
            }}
            className="w-full h-full bg-center bg-no-repeat bg-contain md:hidden"
          /> */}
          <div
            style={{
              backgroundImage: `url(${blok.image.filename})`,
              height: `${desktopHeight}px` || '100%',
            }}
            className="w-full h-full bg-center bg-no-repeat bg-contain"
            // className="hidden w-full h-full bg-center bg-no-repeat bg-contain md:block"
          />
        </div>
      )}
    </SbEditable>
  );
};

export default Image;
