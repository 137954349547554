import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import ReactPlayer from 'react-player';
import cx from 'classnames';

const range = [
  { start: 0, end: 2 },
  { start: 2, end: 4 },
];

const Blok = ({ blok }) => {
  const [more, toggleMore] = useState(false);
  const [url, setVideoUrl] = useState(blok.videos[0].link);

  return (
    <SbEditable content={blok}>
      <section
        className="container grid grid-cols-1 py-8 lg:grid-cols-2 lg:py-28"
        id={blok.id}
      >
        <header className="flex flex-col lg:col-start-2 lg:row-start-1 lg:pl-16 lg:hidden">
          <div className="relative pl-12 mt-4 text-base text-blue-700 uppercase lg:text-lg font-semiBold lg:pl-24 tagline-lg">
            {blok.category}
          </div>
          <h2 className="text-3xl text-blue-700 font-semiBold lg:text-3xl xl:text-6xl">
            {blok.heading}
          </h2>
          <h3 className="flex-1 text-3xl leading-none text-light lg:text-3xl xl:text-6xl">
            {blok.tagline}
          </h3>
        </header>
        <div className="py-8 lg:col-start-1 lg:row-start-1 video-border md:py-14 xl:py-0">
          <div className="video-player">
            <div className="video-player-wrapper">
              <ReactPlayer
                className="react-player"
                url={url}
                width="100%"
                height="100%"
                controls
              />
            </div>
          </div>
        </div>
        <header className="flex flex-col lg:col-start-2 lg:row-start-1 lg:pl-16">
          <div className="relative hidden pl-12 mt-4 text-base text-blue-700 uppercase md:block lg:text-lg font-semiBold lg:pl-24 tagline-lg">
            {blok.category}
          </div>
          <h2 className="hidden text-3xl text-blue-700 md:block font-semiBold lg:text-3xl xl:text-6xl">
            {blok.heading}
          </h2>
          <h3 className="flex-1 hidden pb-8 text-3xl leading-none md:block text-light lg:text-3xl xl:text-6xl">
            {blok.tagline}
          </h3>
          <nav className="flex flex-col">
            {blok.videos
              .slice(
                more ? range[1].start : range[0].start,
                more ? range[1].end : range[0].end
              )
              .map(blk => (
                <button
                  key={blk._uid}
                  type="button"
                  onClick={() => setVideoUrl(blk.link)}
                  className={cx(
                    'rounded-md border-2 border-blue-700 hover:bg-blue-200 mb-5 p-4 flex justify-between items-center',
                    {
                      'bg-blue-700 hover:bg-blue-700': blk.link === url,
                      'text-white': blk.link === url,
                    }
                  )}
                >
                  <div className="flex flex-col text-left">
                    <div className="text-xl font-semiBold">{blk.heading}</div>
                    <div className="text-lg">{blk.data}</div>
                  </div>
                  <div className="items-center text-4xl font-regular">
                    {blk.length}
                  </div>
                </button>
              ))}
            {blok.videos.length > 2 && (
              <div className="flex justify-end">
                <button
                  onClick={() => toggleMore(!more)}
                  type="button"
                  className="inline-flex items-center text-lg bg-white focus:outline-none text-dark-grey font-regular"
                >
                  <span>{more ? 'Zurück' : 'Weitere Videos'}</span>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    viewBox="0 0 64 64"
                    className="inline-block w-10 pl-4"
                  >
                    <path
                      className="fill-current"
                      d="M62.9,29.1L55.3,20c-0.5-0.6-1.5-0.7-2.1-0.2c-0.6,0.5-0.7,1.5-0.2,2.1l7.2,8.6H1.5C0.7,30.5,0,31.2,0,32 c0,0.8,0.7,1.5,1.5,1.5h58.7L53,42.1c-0.5,0.6-0.5,1.6,0.2,2.1c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.9-0.2,1.1-0.5l7.7-9.1	C64.4,33.3,64.4,30.7,62.9,29.1z"
                    />
                  </svg>
                </button>
              </div>
            )}
          </nav>
        </header>
      </section>
    </SbEditable>
  );
};

export default Blok;
