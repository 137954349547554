import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import Link from 'next/link'
import * as Yup from 'yup';
import { useFormspark } from '@formspark/use-formspark';
import {extractDimensionsFromFilename} from '../../utils/img-helpers'
import Image from 'next/image';
import SbEditable from 'storyblok-react';

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Bitte geben Sie eine gültige Emailadresse an.')
    .required('Dies ist ein Pflichtfeld.'),
  name: Yup.string()
    .min(2, 'Zu wenig Zeichen!')
    .max(50, 'Zu viele Zeichen!')
    .required('Dies ist ein Pflichtfeld.'),
  topic: Yup.string()
    .min(2, 'Zu wenig Zeichen!')
    .max(50, 'Zu viele Zeichen!!')
    .required('Dies ist ein Pflichtfeld.'),
});

const ContactFormNew = ({blok}) => {
  const [submit, submitting] = useFormspark({
    formId: 'Cy0s5jld',
  });

  const [submitted, setSubmitted] = useState(false);

  const {height, width} = extractDimensionsFromFilename(blok.image.filename)

  return (
    <SbEditable content={blok}>
      <section className="max-w-screen-2xl mx-auto bg-white grid grid-cols-1 md:grid-cols-2 py-8 lg:py-16 xl:py-40" id={blok.section_id}>
        <div className='px-8 pb-10 md:p-0'>
          <div className='relative w-full pb-full overflow-hidden md:pb-0 md:h-auto'>
            <div className="absolute top-0 left-0 w-full h-full object-cover md:hidden">
              <Image
                src={blok.image.filename}
                alt={blok.image.alt}
                layout="fill"
                objectFit="cover"
                className="w-full h-full"
              />
            </div>
            <div className="hidden md:block">
              <Image
                src={blok.image.filename}
                alt={blok.image.alt}
                layout="responsive"
                width={width}
                height={height}
                className="w-auto h-auto"
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className='px-8 font-semiBold text-blue-700 text-3xl lg:text-4.5xl leading-none lg:pr-12 xl:pr-20'>{blok.title}</h4>
          <Formik
            initialValues={{
              email: '',
              message: '',
              name: '',
              phone: '',
              topic: '',
            }}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
              await submit({ ...values });
              setSubmitted(true);
              resetForm({});
            }}
            validationSchema={FormSchema}
          >
            {props => {
              const { errors, touched, dirty } = props;
              return (
                <Form className="grid grid-cols-1 gap-y-6 px-8 pt-8 lg:py-12 lg:grid-cols-2 lg:gap-x-4 contact-form-new lg:pr-12 xl:pr-20">
                  <label className="relative flex col-span-2 lg:col-span-1">
                    <Field
                      id="name"
                      name="name"
                      placeholder="Ihr Name"
                      className={`flex-1 px-4 py-3 text-sm leading-none xl:text-base xl:px-6 xl:py-4.5 border ${errors.name ? 'border-red-600' : 'border-blue-50'}`}
                    />
                    {errors.name && touched.name ? (
                      <div className="absolute px-4 text-sm text-red-600 xl:px-6 xl:-bottom-6 -bottom-5 lg:text-base md:-bottom-5">
                        {errors.name}
                      </div>
                    ) : null}
                  </label>
                  <label className="relative flex col-span-2 lg:col-span-1">
                    <Field
                      id="email"
                      name="email"
                      placeholder="Ihre Email-Adresse"
                      className={`flex-1 px-4 py-3 text-sm leading-none xl:text-base xl:px-6 xl:py-4.5 border ${errors.email ? 'border-red-600' : 'border-blue-50'}`}
                    />
                    {errors.email && touched.email ? (
                      <div className="absolute px-4 text-sm text-red-600 xl:px-6 xl:-bottom-6 -bottom-5 lg:text-base md:-bottom-5">
                        {errors.email}
                      </div>
                    ) : null}
                  </label>
                  <label className="relative flex col-span-2 lg:col-span-1">
                    <Field
                      id="phone"
                      name="phone"
                      placeholder="Ihre Telefonnummer"
                      className="flex-1 px-4 py-3 text-sm leading-none xl:text-base xl:px-6 xl:py-4.5 border border-blue-50 lg:col-span-1"
                    />
                  </label>
                  <label className="relative flex col-span-2 lg:col-span-1">
                    <Field
                      id="topic"
                      name="topic"
                      placeholder="Betreff"
                      className={`flex-1 px-4 py-3 text-sm leading-none xl:text-base xl:px-6 xl:py-4.5 border ${errors.topic ? 'border-red-600' : 'border-blue-50'}`}
                    />
                    {errors.topic && touched.topic ? (
                      <div className="absolute px-4 text-sm text-red-600 xl:px-6 xl:-bottom-6 -bottom-5 lg:text-base md:-bottom-5">
                        {errors.topic}
                      </div>
                    ) : null}
                  </label>
                  <label
                    className="flex col-span-2"
                  >              
                    <Field
                      id="message"
                      as="textarea"
                      name="message"
                      placeholder="Ihre Nachricht"
                      className="flex-1 h-56 px-4 py-3 text-sm leading-none xl:text-base xl:px-6 xl:py-4 col-span-2 border border-blue-50"
                    />
                  </label>
                  <button
                    type="submit"
                    className="flex-1 col-span-2 px-6 py-3 my-2 leading-none text-white hover:bg-blue-200 bg-blue-700 font-semiBold md:my-0"
                  >
                    Senden
                  </button>
                  {submitted && !dirty && (
                    <div className="w-full col-span-2 text-lg text-center text-blue-700 font-gilroyBold p2">
                      Danke für Ihre Nachricht.
                    </div>
                  )}
                  <footer className="col-span-2 text-xs text-center">
                    Mit dem Absenden der Nachricht stimmen Sie unseren{' '}
                    <Link href="/datenschutzbestimmungen">Datenschutzbestimmungen</Link>{' '}
                    zu.
                  </footer>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </SbEditable>
  );
};

export default ContactFormNew;
