import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://gapi.storyblok.com/v1/api",
  headers: {
    token: process.env.STORYBLOK_PREVIEW_TOKEN,
    version: "draft",
  },

  cache: new InMemoryCache(),
});

export default client;
