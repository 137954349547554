import React from 'react';
import SbEditable from 'storyblok-react';
import Component from '../index';

const Masonry = ({ blok }) => (
  <SbEditable content={blok}>
    <section className="container grid grid-cols-4 gap-2 pb-32 text-center street-masonry lg:auto-rows-fr lg:grid-cols-8">
      <div className="col-span-4 lg:col-span-8 street-data" />
      <div className="col-span-4 bg-gray-100 lg:col-span-5 lg:row-span-3">
        <h3 className="text-3xl text-blue-700">Überblick</h3>
        <p className="py-4">{blok?.overview_description}</p>
        <p>
          <span className="font-semiBold">Quartier:</span>
          {blok?.overview_district}
        </p>
        <p>
          <span className="font-semiBold">Hauswartung:</span>
          {blok?.overview_housekeeper}
        </p>
      </div>
      <div className="col-span-4 bg-gray-100 lg:col-span-3 lg:row-span-3">
        <h3>Erstellung</h3>
        {blok?.creation_data}
      </div>
    </section>
  </SbEditable>
);

export default Masonry;
