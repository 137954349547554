import React from 'react';
import SbEditable from 'storyblok-react';

const Grid = ({ blok }) => {
  const href = blok?.button_link?.cached_url;
  return (
    <SbEditable content={blok}>
      <section className="container flex flex-col justify-between px-24 py-12 mt-24 text-white bg-blue-700 lg:flex-row" id={blok.id}>
        <h2 className="text-4xl font-bold">{blok.heading}</h2>
        <a className="inline-flex hover:bg-blue-200 items-center justify-center px-6 py-2 my-4 text-lg bg-white lg:my-0 lg:text-2xl text-dark-grey font-regular whitespace-nowrap" href={href}>
          <span>Mehr erfahren</span>
          <svg viewBox="0 0 64 64" className="hidden w-10 pl-4 lg:inline-block">
            <path className="fill-current" d="M62.9,29.1L55.3,20c-0.5-0.6-1.5-0.7-2.1-0.2c-0.6,0.5-0.7,1.5-0.2,2.1l7.2,8.6H1.5C0.7,30.5,0,31.2,0,32 c0,0.8,0.7,1.5,1.5,1.5h58.7L53,42.1c-0.5,0.6-0.5,1.6,0.2,2.1c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.9-0.2,1.1-0.5l7.7-9.1	C64.4,33.3,64.4,30.7,62.9,29.1z" />
          </svg>
        </a>
      </section>
    </SbEditable>
  );
};

export default Grid;
