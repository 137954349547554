import React, { useRef, useState } from "react";
import Link from "next/link";
import cx from "classnames";

const Dropdown = ({ navigation, onClick }) => {
  const wrapperRef = useRef(null);
  const [isActive, toggleDropdownVisibility] = useState(false);

  return (
    <>
      <button
        ref={wrapperRef}
        className="inline-flex pl-1 text-white lg:hidden lg:text-black focus:outline-none"
        type="button"
        onClick={() => toggleDropdownVisibility(!isActive)}
        aria-label="Expand menu"
      >
        <svg className="w-3 h-3" viewBox="0 0 612 612">
          {isActive ? (
            <path
              className="fill-current"
              d="M7.5,477.2c10,10.1,26.2,10.1,36.2,0L306,189.4l262.3,287.8c10,10.1,26.2,10.1,36.2,0c10-10,10-26.4,0-36.4L325.4,134.6	c-5.3-5.4-12.4-7.7-19.4-7.3c-7-0.4-14.1,1.9-19.4,7.3L7.5,440.8C-2.5,450.9-2.5,467.2,7.5,477.2z"
            />
          ) : (
            <path
              className="fill-current"
              d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782 c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296 c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"
            />
          )}
        </svg>
      </button>
      <div
        className={cx("relative dropdown-menu min-w-full w-auto right-0", {
          block: isActive,
          hidden: !isActive,
        })}
      >
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {navigation?.[0].navlinks
            .filter((item) => !item?.hide)
            .map((item) => {
              const { _uid, name, url: linkUrl, linktype } = item;
              let link = linkUrl?.url || linkUrl?.cached_url;
              link =
                link?.startsWith("/") || linktype === "url" ? link : `/${link}`;
              return (
                <Link key={_uid} href={link} role="menuitem">
                  <a className="block px-2 py-2 mx-4 my-2 leading-3 focus:outline-none">
                    {name}
                  </a>
                </Link>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
