import React from 'react';
import SbEditable from 'storyblok-react';
import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { ChevronDown, ChevronUp } from 'heroicons-react';

const Accordion2Item = ({ blok }) => (
  <SbEditable content={blok}>
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionItemState>
            {({ expanded }) => (expanded
              ? (
                <h2 className="flex text-lg bg-gray-300 text-light-green font-semiBold">
                  <span className="flex-1 p-1 pl-3">{blok.heading}</span>
                  <div className="flex items-center px-2 text-white bg-light-green"><ChevronUp /></div>
                </h2>
              )
              : (
                <h2 className="flex text-lg text-blue-700 bg-gray-300 font-semiBold">
                  <span className="flex-1 p-1 pl-3">{blok.heading}</span>
                  <div className="flex items-center px-2 text-white bg-light-green"><ChevronDown /></div>
                </h2>
              ))}
          </AccordionItemState>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p className="pt-4 pb-2 pr-0 text-lg lg:text-justify lg:pr-18">{blok.content}</p>
      </AccordionItemPanel>
    </>
  </SbEditable>
);

export default Accordion2Item;
