import React, { useRef } from "react";
import { isEmpty } from "ramda";
import DropdownDesktopMenuItem from "./DropdownDesktopMenuItem";
import DropdownMobileMenuItem from "./DropdownMobileMenuItem";
import NavigationLink from "./NavigationLink";

import useOutsideClick from "../hooks/useOutsideClick";

const Navigation = ({ navigation = [], onClick }) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, onClick);
  return (
    <ul
      ref={wrapperRef}
      className="box-content absolute top-0 right-0 w-64 h-screen text-center text-white transition transform translate-x-full bg-blue-800 lg:text-left lg:translate-x-0 lg:bg-transparent lg:flex lg:static lg:w-auto lg:p-0 lg:h-auto lg:text-current"
    >
      <li className="flex justify-center pt-12 pb-8 lg:hidden ">
        <img
          alt="logo"
          className="box-border h-12"
          src="/BGL_Logo_FullWhite.svg"
        />
      </li>
      {navigation?.[0].navlinks
        ?.filter((navlink) => !navlink.link?.[0]?.hide)
        .map(({ _uid, link, sub_navbar }) => {
          const hasSubmenu = sub_navbar && sub_navbar.length;
          const { name, url: linkUrl } = link?.[0] || {};
          const { url, cached_url } = linkUrl;
          const navigationUrl = url || cached_url;
          return (
            <li key={_uid} className="pb-3 ml-0 lg:ml-4 xl:ml-8 lg:pb-0">
              {hasSubmenu == true && (
                <>
                  <div className="relative inline-flex justify-center lg:flex expandable-menu gap-2">
                    <NavigationLink
                      className="p-0 text-base lg:text-lg"
                      name={name}
                      slug={navigationUrl}
                      onClick={onClick}
                    />
                    <DropdownDesktopMenuItem navigation={sub_navbar} />
                  </div>
                  <DropdownMobileMenuItem
                    navigation={sub_navbar}
                    onClick={onClick}
                  />
                </>
              )}
              {!hasSubmenu && (
                <NavigationLink
                  className="p-1 text-base lg:text-lg"
                  name={name}
                  slug={navigationUrl}
                  onClick={onClick}
                />
              )}
            </li>
          );
        })}
      <li className="pt-8 ml-0 lg:ml-4 lg:pt-0">
        <a
          href="https://portal.bgl-zuerich.ch/B5pQuorum/static/#/login"
          target="_blank"
          title="Login Mieterportal"
          rel="noreferrer"
          className="px-3 py-2 ml-0 text-base text-white bg-blue-700 hover:bg-blue-200 lg:px-6 lg:py-3 lg:text-lg font-regular lg:ml-6"
        >
          Login Mieterportal
        </a>
      </li>
    </ul>
  );
};

export default Navigation;
