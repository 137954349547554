import React from 'react';
import Link from 'next/link'

const Footer = () => (
  <footer className="bg-blue-900">
    <section className="container flex flex-col justify-between py-5 text-center text-gray-100 md:flex-row md:text-left">
      <div className="order-2 pt-6 md:order-1 md:pt-0">&copy; 2021 - 2023 Baugenossenschaft Letten</div>
      <ul className="flex flex-col order-1 list-none md:flex-row md:order-2">
        <li className="lg:pr-6"><Link href="/impressum">Impressum</Link></li>
        <li className="lg:pr-6"><Link href="/datenschutzbestimmungen">Datenschutzbestimmungen</Link></li>
      </ul>
    </section>
  </footer>
);

export default Footer;
