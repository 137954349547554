import React from 'react';
import SbEditable from 'storyblok-react';

const Teaser2 = ({ blok }) => (
  <SbEditable content={blok}>
    <article className={blok.className}>
      <header>
        <div className="relative pl-12 mt-8 text-base text-blue-700 uppercase font-semiBold lg:mt-0 lg:text-lg lg:pl-24 tagline-lg">{blok.category}</div>
        <h2 className="text-3xl leading-snug text-blue-700 font-semiBold lg:text-6xl">{blok.heading}</h2>
        <h3 className="text-3xl font-light leading-none lg:inline-block text-dark-grey lg:text-6xl">{blok.tagline}</h3>
      </header>
      <p className="pt-6 pr-0 text-lg leading-relaxed lg:text-justify lg:pb-8 lg:pr-18 lg:pb-0 lg:pt-10">{blok.content}</p>
      {blok.footer && <footer className="text-lg font-semiBold lg:pr-18">{blok.footer}</footer>}
    </article>
  </SbEditable>
);

export default Teaser2;
