import Accordion2 from './blocks/Accordion2';
import Accordion2Item from './blocks/Accordion2Item';
import AccordionContainer from './blocks/AccordionContainer';
import AccordionSection from './blocks/AccordionSection';
import AccordionWithImage from './blocks/AccordionWithImage';
import AreaChart from './blocks/AreaChart';
import Article from './blocks/Article';
import ArticleWithText from './blocks/ArticleWithText';
import BlockTeaser from './blocks/BlockTeaser';
import CardDownload from './blocks/CardDownload';
import CardPartner from './blocks/CardPartner';
import Carousel from './blocks/Carousel';
import CarouselItem from './blocks/CarouselItem';
import ContactDataBox from './blocks/ContactDataBox';
import ContactForm from './blocks/ContactForm';
import ContactFormNew from './blocks/ContactFormNew';
import DevelopmentTimeline from './blocks/DevelopmentTimeline';
import DonutChart from './blocks/DonutChart';
import DownloadableItem from './blocks/DownloadableItem';
import DownloadsMasonry from './blocks/DownloadsMasonry';
import EmptyTeamGridItem from './blocks/EmptyTeamGridItem';
import FaqGrid from './blocks/FaqGrid';
import GoogleMap from './blocks/GoogleMap';
import Grid from './blocks/Grid';
import GridImageCaption from './blocks/GridImageCaption';
import GridPartner from './blocks/GridPartner';
import GridWithReorder from './blocks/GridWithReorder';
import HeadingBgImage from './blocks/HeadingBgImage';
import HorizontalCard from './blocks/HorizontalCard';
import Image from './blocks/Image';
import Image2 from './blocks/Image2';
import ImageLink from './blocks/ImageLink';
import ImageTitleText from './blocks/ImageTitleText';
import InlineTeaser from './blocks/InlineTeaser';
import JumbotronVideo from './blocks/JumbotronVideo';
import Lightbox from './blocks/Lightbox';
import LineChart from './blocks/LineChart';
import LinkButton from './blocks/LinkButton';
import LinksGrid from './blocks/LinksGrid';
import Masonry from './blocks/Masonry';
import MasonryItem from './blocks/MasonryItem';
import PieChart from './blocks/PieChart';
import Placeholder from './blocks/Placeholder';
import Process from './blocks/Process';
import ProjectProgress from './blocks/ProjectProgress';
import RealEstateData from './blocks/RealEstateData';
import RealEstateDataBox from './blocks/RealEstateDataBox';
import SectionWithLink from './blocks/SectionWithLink';
import StreetData from './blocks/StreetData';
import StreetData2 from './blocks/StreetData2';
import StreetData3 from './blocks/StreetData3';
import TeamGrid from './blocks/TeamGrid';
import TeamGridItem from './blocks/TeamGridItem';
import Teaser from './blocks/Teaser';
import Teaser2 from './blocks/Teaser2';
import TeaserContent from './blocks/TeaserContent';
import TeaserHeading from './blocks/TeaserHeading';
import TeaserHorizontal from './blocks/TeaserHorizontal';
import VerticalSlider from './blocks/VerticalSlider';
import VerticalSliderItem from './blocks/VerticalSliderItem';
import VideoPlayer from './blocks/VideoPlayer';
import CardIconText from './blocks/CardIconText'


const Components = {
  Accordion2,
  Accordion2Item,
  AccordionContainer,
  AccordionSection,
  AccordionWithImage,
  AreaChart,
  Article,
  ArticleWithText,
  BlockTeaser,
  Carousel,
  CarouselItem,
  ContactDataBox,
  ContactForm,
  DonutChart,
  DownloadableItem,
  DownloadsMasonry,
  GoogleMap,
  Grid,
  GridWithReorder,
  HorizontalCard,
  Image,
  Image2,
  ImageLink,
  InlineTeaser,
  JumbotronVideo,
  Lightbox,
  LineChart,
  LinkButton,
  LinksGrid,
  Masonry,
  MasonryItem,
  PieChart,
  RealEstateData,
  RealEstateDataBox,
  SectionWithLink,
  StreetData,
  StreetData2,
  StreetData3,
  TeamGrid,
  TeamGridItem,
  EmptyTeamGridItem,
  Teaser,
  Teaser2,
  TeaserContent,
  TeaserHeading,
  TeaserHorizontal,
  VerticalSlider,
  VerticalSliderItem,
  VideoPlayer,
  ContactFormNew,
  HeadingBgImage,
  ImageTitleText,
  GridImageCaption,
  FaqGrid,
  ProjectProgress,
  Process,
  DevelopmentTimeline,
  CardIconText,
  CardDownload,
  CardPartner,
  GridPartner
};

const PageComponent = ({ blok, idx, ...props }) => {
  if (blok && typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component];
    return <Component blok={blok} idx={idx} {...props} />;
  }
  return <Placeholder componentName={blok ? blok.component : null} />;
};

export default PageComponent;
