import React from 'react';
import SbEditable from 'storyblok-react';
import Component from '../index';

const Grid = ({ blok }) => (
  <SbEditable content={blok}>
    <section className="container grid grid-cols-1 gap-4 py-16 lg:grid-cols-8 links-grid">
      {blok.items.map((blk, index) => <Component blok={blk} key={blk._uid} idx={index} />)}
    </section>
  </SbEditable>
);

export default Grid;
