import React from 'react';
import Link from 'next/link'

const Logo = ({ className = '', src, logoClassName = 'h-10 lg:h-24' }) => (
  <h1 className={`lg:ml-0 flex flex-1 ${className}`}>
    <Link href="/">
      <img alt="logo" className={`box-border ${logoClassName}`} src={src} />
    </Link>
  </h1>
);

export default Logo;
