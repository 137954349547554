import React from 'react';
import SbEditable from 'storyblok-react';
import Component from '../index';

const Masonry = ({ blok }) => (
  <SbEditable content={blok}>
    <section className="container pb-24 text-center masonry">
      {blok?.items.map((blk) => <Component blok={blk} key={blk._uid} />)}
    </section>
  </SbEditable>
);

export default Masonry;
