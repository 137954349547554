import React from 'react';
import ArticleContent from './ArticleContent.jsx';
import ArticleContentRight from './ArticleContentRight.jsx';

const Article = ({ blok }) => (
  <section className="container grid grid-flow-row grid-cols-1 gap-8 py-8 lg:gap-16 lg:py-16 lg:grid-cols-3">
    {blok.alignRight ? (
      <ArticleContentRight blok={blok} />
    ) : (
      <ArticleContent blok={blok} />
    )}
  </section>
);

export default Article;
