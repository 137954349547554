import Link from "next/link"
import { useRouter } from "next/router"

const BreadCrumbs = () => {
  const path = useRouter().asPath.split('?')[0].split('/').filter((item) => item !== '')

  return (
    <nav className="flex mb-5 lg:mb-6" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center font-gilroyMedium text-xs lg:text-sm text-gray-400 leading-none">
        <li>
          <Link href='/'>
            <a className="mr-1">
              Startseite
            </a>
          </Link>
        </li>
        {path.map((page, index) => (
          <li key={page}>
            <div className="flex items-center">
              &gt;
              {index === path.length-1 
              ? <span aria-current={true} className="capitalize underline text-blue-700 mx-1">{page}</span>
              : <Link
                href={`/${page}`}                
              >
                <a className="capitalize mx-1">{page}</a>
              </Link>
              }
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default BreadCrumbs