import React from 'react';
import SbEditable from 'storyblok-react';

const HorizontalCard = ({ blok }) => (
  <SbEditable content={blok}>
    <figure className="flex flex-col transition duration-500 ease-in-out bg-light-olive-green lg:flex-row hover:shadow-xl">
      <div className="relative w-full bg-no-repeat bg-cover square-img overfow-hidden lg:w-64" style={{ backgroundImage: `url(${blok.image.filename})` }} />
      <figcaption className="flex flex-col flex-1 py-6 pr-8">
        <div className="relative pl-16 ml-8 text-lg text-white uppercase blue-700 font-semiBold tagline-white">{blok.category}</div>
        <h2 className="mb-2 ml-8 text-2xl blue-700 font-semiBold">{blok.heading}</h2>
        <p className="flex-1 pb-4 ml-8 text-sm leading-snug lg:text-justify">{blok.content}</p>
        <p className="py-1 pl-8 bg-white">{blok.metadata}</p>
      </figcaption>
    </figure>
  </SbEditable>
);

export default HorizontalCard;
