import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import ModalVideo from 'react-modal-video';
import SbEditable from 'storyblok-react';

const Component = ({ blok }) => {
  const [isOpen1, setOpenVideo1] = useState(false);
  const [isOpen2, setOpenVideo2] = useState(false);
  return (
    <SbEditable content={blok}>
      <div className="flex justify-center mt-8 lg:justify-end lg:-mt-40">
        {!blok.video.filename && (
          <ModalVideo channel="youtube" autoplay loop isOpen={isOpen1} videoId={blok.video_url} onClose={() => setOpenVideo1(false)} />
        )}
        {blok.video.filename && <VideoModal isOpen={isOpen1} url={blok.video.filename} onClose={() => setOpenVideo1(false)} />}
        
        {!blok.video2.filename && (
          <ModalVideo channel="youtube" autoplay loop isOpen={isOpen2} videoId={blok.video2_url} onClose={() => setOpenVideo2(false)} />
        )}
        {blok.video2.filename && <VideoModal isOpen={isOpen2} url={blok.video2.filename} onClose={() => setOpenVideo2(false)} />}

        {blok.lightbox && blok.lightbox.map((item) => (
          <figure key={item.id} className="teaser-lightbox-item">
            <img src={item.filename} alt={item.alt} />
            <figcaption className="flex flex-wrap text-sm text-center uppercase lg:text-base">
              <button className="flex items-center justify-center w-full py-1 bg-gray-100 hover:bg-blue-200 flex-4 lg:flex-1 lg:w-auto lg:py-3 md:mr-1" type="button" onClick={() => setOpenVideo1(true)}>
                <svg viewBox="0 0 100.25 100.25" className="inline w-5 h-5 mr-2 lg:w-8 lg:h-8">
                  <path d="M69.817,48.243l-30-19.5c-0.461-0.3-1.05-0.322-1.533-0.061c-0.483,0.263-0.785,0.769-0.785,1.318v39 c0,0.55,0.301,1.056,0.785,1.318c0.224,0.121,0.47,0.182,0.715,0.182c0.285,0,0.57-0.081,0.817-0.242l30-19.5 c0.426-0.276,0.683-0.75,0.683-1.258S70.243,48.519,69.817,48.243z M40.5,66.237V32.764L66.248,49.5L40.5,66.237z" />
                  <path d="M49.5,6.5c-23.71,0-43,19.29-43,43s19.29,43,43,43s43-19.29,43-43S73.21,6.5,49.5,6.5z M49.5,89.5 c-22.056,0-40-17.944-40-40s17.944-40,40-40s40,17.944,40,40S71.556,89.5,49.5,89.5z" />
                </svg>
                <span className="uppercase xl:text-lg">BGL-Film IN MUNDART</span>
              </button>
              <button className="flex-1 lg:flex-none px-5 py-1 lg;py-3 bg-gray-100 hover:bg-blue-200 xl:text-lg focus:outline-none" type="button" onClick={() => setOpenVideo2(true)}>
                IN DEUTSCH
              </button>

            </figcaption>
          </figure>
        ))}
      </div>
    </SbEditable>
  );
};

const VideoModal = (props) => {
  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden text-left shadow-xl transition-all sm:my-8 max-w-[540px]">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <video width="940" height="529" controls>
                      <source src={props.url} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Component;
