import classNames from 'classnames';
import SbEditable from 'storyblok-react';

const Component = ({ blok }) => {
  return (
  <SbEditable content={blok}>
    <div className="h-80">
      <div className={classNames("flex w-full h-full align-top", {
        'bg-blue-200': blok.occupation === '1',
        'bg-light-green': blok.occupation === '2',
        'bg-light-olive-green': blok.occupation === '3',
        'bg-orange': blok.occupation === '4',
      })}>
        <div className="w-full h-full text-white p-6 lg:px-10 lg:pt-10 lg:pb-6 flex flex-col">
          <h3 className="font-bold text-xl mt-auto pb-4 tracking-wide">{blok.text}</h3>
        </div>
      </div>
    </div>
  </SbEditable>
);
      }

export default Component;
