import React from 'react';
import SbEditable from 'storyblok-react';
import Component from '../index';

const Blok = ({ blok }) => (
  <SbEditable content={blok}>
    <div className="container grid grid-cols-1 p-8 -mt-24 bg-gray-100 lg:grid-cols-4 lg:p-0">
      {blok.columns.map((blk) => <Component blok={blk} key={blk._uid} />)}
    </div>
  </SbEditable>
);

export default Blok;
