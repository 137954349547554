import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import Link from 'next/link'
import * as Yup from 'yup';
import { useFormspark } from '@formspark/use-formspark';
import {ReactComponent as Icon} from '../../public/icons/Icon_Email-Contact.svg';

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Bitte geben Sie eine gültige Emailadresse an.')
    .required('Dies ist ein Pflichtfeld.'),
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Dies ist ein Pflichtfeld.'),
  topic: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Dies ist ein Pflichtfeld.'),
});

const Placeholder = () => {
  const [submit, submitting] = useFormspark({
    formId: 'Cy0s5jld',
  });

  const [submitted, setSubmitted] = useState(false);

  return (
    <div className="bg-white shadow-2xl lg:-mt-32">
      <header className="flex bg-gray-100">
        <div className="w-20 h-20 m-6 bg-blue-700 rounded-md">
          <img src="/icons/Icon_Email-Contact.svg" alt="icon"/>
        </div>
        <h4 className="flex items-center px-2 py-6 text-lg lg:text-2xl font-regular">
          Nehmen Sie mit uns Kontakt auf.
          <br />
          Wir melden uns.
        </h4>
      </header>
      <Formik
        initialValues={{
          email: '',
          message: '',
          name: '',
          phone: '',
          topic: '',
        }}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          await submit({ ...values });
          setSubmitted(true);
          resetForm({});
        }}
        validationSchema={FormSchema}
      >
        {props => {
          const { errors, touched, dirty } = props;
          return (
            <Form className="grid grid-cols-1 gap-2 px-8 py-12 lg:p-12 lg:grid-cols-2 lg:gap-6 contact-form">
              <label className="relative flex col-span-2 pb-3 lg:col-span-1">
                <Field
                  id="name"
                  name="name"
                  placeholder="Ihr Name"
                  className="flex-1 px-6 py-4 bg-gray-100 rounded-md"
                />
                {errors.name && touched.name ? (
                  <div className="absolute px-6 text-sm text-red-600 -bottom-2 lg:text-base md:-bottom-5">
                    {errors.name}
                  </div>
                ) : null}
              </label>
              <label className="relative flex col-span-2 pb-3 lg:col-span-1">
                <Field
                  id="email"
                  name="email"
                  placeholder="Ihre Email-Adresse"
                  className="flex-1 px-6 py-4 bg-gray-100 rounded-md"
                />
                {errors.email && touched.email ? (
                  <div className="absolute px-6 text-sm text-red-600 lg:text-base -bottom-2 md:-bottom-5">
                    {errors.email}
                  </div>
                ) : null}
              </label>
              <label className="relative flex col-span-2 pb-3 lg:col-span-1">
                <Field
                  id="phone"
                  name="phone"
                  placeholder="Ihre Telefonnummer"
                  className="flex-1 col-span-2 px-6 py-4 bg-gray-100 rounded-md lg:col-span-1"
                />
              </label>
              <label className="relative flex col-span-2 pb-3 lg:col-span-1">
                <Field
                  id="topic"
                  name="topic"
                  placeholder="Betreff"
                  className="flex-1 px-6 py-4 bg-gray-100 rounded-md"
                />
                {errors.topic && touched.topic ? (
                  <div className="absolute px-6 text-sm text-red-600 lg:text-base -bottom-2 md:-bottom-4">
                    {errors.topic}
                  </div>
                ) : null}
              </label>
              <label
                htmlFor="message"
                className="flex-1 col-span-2 pt-6 text-2xl font-semiBold"
              >
                Ihre Nachricht
              </label>
              <Field
                id="message"
                as="textarea"
                name="message"
                className="flex-1 h-56 col-span-2 bg-gray-100 rounded-md"
              />
              <button
                type="submit"
                className="flex-1 col-span-2 p-2 my-3 text-xl text-white hover:bg-blue-200 bg-blue-700 rounded-md font-semiBold md:my-0"
              >
                Senden
              </button>
              {submitted && !dirty && (
                <div className="w-full col-span-2 text-lg text-center text-blue-700 font-gilroyBold p2">
                  Danke für Ihre Nachricht.
                </div>
              )}
              <footer className="col-span-2 text-xs text-center">
                Mit dem Absenden der Nachricht stimmen Sie unseren{' '}
                <Link href="/datenschutzbestimmungen">Datenschutzbestimmungen</Link>{' '}
                zu.
              </footer>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Placeholder;
