import cx from 'classnames/bind';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import StoryblokService from '../utils/storyblok-ssr-service';
import Footer from './Footer';
import Logo from './Logo';
import Navigation from './Navigation';
import BreadCrumbs from './blocks/BreadCrumbs';
import Component from './index';
import AnchorNav from './blocks/AnchorNav'

const Layout = ({
  backgroundImage,
  backgroundVideo,
  galleryImages = {},
  children,
  heading,
  lead,
  navigation,
  slug,
  preview,
  anchorNavigation
}) => {
  const [isActive, toggleDropdownVisibility] = useState(null);
  const [currentImgCount, setCurrentImgCount] = useState(0);
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', () => toggleDropdownVisibility(false))
  }, [router, toggleDropdownVisibility])

  const onClick = imgCount => setCurrentImgCount(imgCount);

  const imagesCount = galleryImages.length - 1;

  const nextImage = () => setCurrentImgCount(currentImgCount + 1);
  const prevImage = () => setCurrentImgCount(currentImgCount - 1);

  return (
    <>
      {preview && (
        <div className="p-2 text-center bg-red-100">SSR preload mode</div>
      )}
      <nav
        className={cx(
          'fixed top-0 right-0 left-0 bottom-0 lg:relative w-full pointer-events-none z-50 lg:inset-auto'
        )}
      >
        <div
          className={cx(
            'flex items-center justify-between flex-wrap py-2 lg:py-4 lg:container bg-white lg:bg-transparent transform transition duration-500 ease-in-out pointer-events-auto',
            { '-translate-x-64': isActive }
          )}
        >
          <Logo src="/BGL_Logo_CMYK.svg" className="ml-4" />
          {isActive ? (
            <button
              aria-label="Main menu"
              className="w-5 h-5 mr-6 text-blue-800 pointer-events-none lg:hidden focus:outline-none"
              type="button"
            >
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
              </svg>
            </button>
          ) : (
            <button
              aria-label="Main menu"
              className="w-5 h-5 mr-6 text-blue-800 lg:hidden focus:outline-none"
              onClick={() => toggleDropdownVisibility(true)}
              type="button"
            >
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          )}
          <Navigation
            navigation={navigation?.content?.navbar}
            onClick={() => toggleDropdownVisibility(false)}
          />
        </div>
      </nav>
      <main
        className={cx(
          'flex flex-col min-h-screen transition duration-500 ease-in-out',
          { '-translate-x-64': isActive },
          slug
        )}
      >
        <article className="relative py-14 lg:pt-0 lg:pb-32">
          {backgroundVideo ? (
            <>
              <header className="mx-auto bg-no-repeat lg:relative container-wrapper ">
                <div className="flex">
                  <div className="relative overflow-hidden heading-bg-image lg:w-2/3">
                    <img
                      src={`${galleryImages[currentImgCount]?.image?.filename}`}
                      alt={`${galleryImages[currentImgCount]?.image?.alt}`}
                      className="w-full"
                    />
                    {currentImgCount !== 0 ? (
                      <button
                        className="absolute z-10 h-12 p-2 pr-3 text-blue-700 bg-gray-300 right-20 bottom-3 lg:block lg:text-black focus:outline-none"
                        type="button"
                        aria-label="Previous image"
                        onClick={prevImage}
                      >
                        <svg
                          className="visible w-6 h-6 lg:w-8 lg:h-8"
                          viewBox="0 0 612 612"
                        >
                          <path
                            className="fill-current"
                            d="M477.2,604.5c10.1-10,10.1-26.2,0-36.2L189.4,306L477.2,43.7c10.1-10,10.1-26.2,0-36.2c-10.1-10-26.4-10-36.4,0 L134.6,286.6c-5.4,5.3-7.7,12.4-7.3,19.4c-0.4,7,1.9,14.1,7.3,19.4l306.2,279.1C450.9,614.5,467.2,614.5,477.2,604.5z"
                          />
                        </svg>
                      </button>
                    ) : null}
                    {imagesCount !== currentImgCount ? (
                      <button
                        className="absolute z-10 h-12 p-2 pl-3 text-blue-700 bg-gray-300 right-8 lg:block lg:text-black focus:outline-none bottom-3"
                        type="button"
                        aria-label="Next image"
                        onClick={nextImage}
                      >
                        <svg
                          className="visible w-6 h-6 lg:w-8 lg:h-8"
                          viewBox="0 0 612 612"
                        >
                          <path
                            className="fill-current"
                            d="M134.8,7.5c-10.1,10-10.1,26.2,0,36.2L422.6,306L134.8,568.3c-10.1,10-10.1,26.2,0,36.2c10.1,10,26.4,10,36.4,0 l306.2-279.1c5.4-5.3,7.7-12.4,7.3-19.4c0.4-7-1.9-14.1-7.3-19.4L171.2,7.5C161.1-2.5,144.8-2.5,134.8,7.5z"
                          />
                        </svg>
                      </button>
                    ) : null}
                  </div>
                  <div className="relative hidden lg:block lg:w-1/3">
                    <div className="top-0 bottom-0 left-0 right-0 lg:absolute heading-bg-video xl:left-6">
                      <ReactPlayer
                        className="react-player"
                        url={backgroundVideo}
                        height="100%"
                        width="100%"
                        loop
                        muted
                        playing
                        playsinline
                      />
                    </div>
                  </div>
                </div>
                <div className="container flex gray-section3">
                  <div className="relative my-8 lg:mt-16 lg:mb-12 heading-text">
                    <BreadCrumbs/>
                    <h2 className="text-4xl leading-none text-blue-700 font-gilroyBold lg:text-7xl">
                      {heading}
                    </h2>
                    <p className="text-4xl leading-none lg:text-7xl font-regular">
                      {lead}
                    </p>
                  </div>
                </div>
              </header>
              {galleryImages && galleryImages.length ? (
                <section
                  className={` hidden lg:grid container grid-cols-1 lg:grid-cols-${galleryImages.length} grid-flow-row gap-8 py-16 gallery-images`}
                >
                  {galleryImages.length &&
                    galleryImages.map((blk, index) => (
                      <Component
                        blok={blk}
                        key={blk._uid}
                        onClick={() => onClick(index)}
                      />
                    ))}
                </section>
              ) : null}
              <AnchorNav anchorNavigation={anchorNavigation}/>
            </>
          ) : (
            <>
              <header
                className="pb-40 bg-center bg-no-repeat bg-cover pt-36 lg:pb-0 lg:pt-56"
                style={{ backgroundImage: `url(${backgroundImage?.filename})` }}
              >
                <div className="container pb-0 text-white lg:pb-44 lg:border-l-2 lg:border-l-white">
                  <h2 className="text-4xl leading-tight font-gilroyBold lg:pt-8 lg:text-7xl text-shadow">
                    {heading}
                  </h2>
                  <p className="text-4xl leading-tight lg:leading-extra-tight lg:text-7xl font-regular text-shadow">
                    {lead}
                  </p>
                </div>
              </header>
              <AnchorNav anchorNavigation={anchorNavigation}/>
            </>
          )}

          {children}
        </article>
        {<aside
          className="bg-blue-800"
          style={{ backgroundImage: 'url("/BGL_Footer-Lines.svg")' }}
        >
          <div className="container flex flex-col items-center pt-12 mx-auto text-white xl:pt-16 lg:flex-row lg:items-start">
            <Logo
              className="pb-12 lg:pb-0"
              logoClassName="h-20 lg:h-28"
              src="/BGL_Logo_FullWhite.svg"
            />
            {navigation?.content?.footer?.[0].navlinks?.map(navLink => {
              const { _uid, name, url, linktype } = navLink?.link?.[0] || {};
              let link = url?.url || url?.cached_url;
              link = link?.startsWith('/') || linktype === 'url' ? link : `/${link}`;
              return (
                <nav className="pb-12 pr-0 text-center text-white lg:pr-12 xl:pr-32 lg:text-left" key={_uid}>
                  <h2 className="pb-6 font-gilroyBold tracking-wider">
                    {link ?
                      <a className="text-blue-200 uppercase xl:text-lg hover:text-white" href={link}>{name}</a> :
                      <span  className="text-blue-200 uppercase xl:text-lg">{name}</span>
                    }
                  </h2>
                  <ul>
                    {navLink?.sub_navbar && navLink?.sub_navbar?.[0]?.navlinks?.map(subNavLink => {
                      const { _uid, name, url, linktype } = subNavLink;
                      let link = url?.url || url?.cached_url;
                      link = link?.startsWith('/') || url?.linktype === 'url' ? link : `/${link}`
                      return (
                        <li key={_uid}>
                          <Link href={link}>
                            <a className="leading-relaxed transition duration-300 xl:text-lg lg:text-lg top-header__link hover:text-blue-200">{name}</a>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
             )})}
            <div className="pb-12 text-center lg:text-left">
              <h2 className="pb-6 tracking-wider text-white uppercase font-gilroyBold xl:text-lg">
                Baugenossenschaft Letten
              </h2>
              <ul className="pb-6">
                <li className="leading-relaxed xl:text-lg">
                  Baugenossenschaft Letten
                </li>
                <li className="leading-relaxed xl:text-lg">
                  Schaffhauserstrasse 68
                </li>
                <li className="leading-relaxed xl:text-lg">CH-8057 Zürich</li>
              </ul>
              <ul>
                <li className="leading-relaxed xl:text-lg">
                  <Link href="tel:0443657500">044 365 75 00</Link>
                </li>
                <li className="leading-relaxed xl:text-lg">
                  <Link href="mailto:info@bgl-zuerich.ch">
                    info&#64;bgl-zuerich&#46;ch
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          </aside>}
        <Footer />
        {StoryblokService.bridge()}
      </main>
    </>
  );
};

export default Layout;
