import React from 'react';
import SbEditable from 'storyblok-react';
import Component from '../index';

const Teaser = ({ blok }) => (
  <SbEditable content={blok}>
    <header className={`flex flex-col ${blok.className}`}>
      <div className="relative pl-12 text-base text-blue-700 uppercase lg:text-lg lg:pl-24 font-semiBold tagline-lg">{blok.backdrop}</div>
      <h2 className="text-3xl leading-snug text-blue-700 font-semiBold lg:text-4xl xl:text-6xl lg:pt-2">{blok.headline}</h2>
      <h3 className="pb-10 text-3xl leading-none text-light lg:text-4xl xl:text-6xl lg:pb-8">{blok.tagline}</h3>
      <p className="flex-1 flex-shrink-0 pb-12 text-lg leading-snug lg:text-justify xl:leading-relaxed lg:pb-0">{blok.content}</p>
      <div className="flex-shrink mt-6">
        {blok.button?.map((blk, index) => (
          <Component blok={blk} key={blk._uid} idx={index} />
        ))}
      </div>
    </header>
  </SbEditable>
);

export default Teaser;
