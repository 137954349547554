import Image from "next/image"
import SbEditable from "storyblok-react"
import { extractDimensionsFromFilename } from "utils/img-helpers"

const GridImageCaption = ({blok}) => {

    const {height: heightImg1, width: widthImg1} = extractDimensionsFromFilename(blok.image_1.filename)
    
    return (
        <SbEditable content={blok}>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-4 md:grid-rows-6">
                <div className="relative col-span-2 md:row-span-6">
                    <Image
                        src={blok.image_1.filename}
                        alt={blok.image_1.alt}
                        height={heightImg1}
                        width={widthImg1}
                        layout="responsive"
                        objectFit="cover"
                    />
                </div>
                <div className="relative w-full h-0 pb-full md:row-span-4">
                    <Image
                        src={blok.image_2.filename}
                        alt={blok.image_2.alt}
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
                <div className="relative w-full h-0 pb-full md:row-span-4">
                    <Image
                        src={blok.image_3.filename}
                        alt={blok.image_3.alt}
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
                <p className="col-span-2 text-center text-sm leading-5 text-blue-700 font-gilroyMedium">{blok.caption}</p>
            </div>
        </SbEditable>
    )
}

export default GridImageCaption