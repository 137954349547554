import React from 'react';

const Component = ({ data }) => (
  <section className="pt-24 pb-32 opacity-0 lg:pt-64 lg:pb-48 vertical-slide">
    <header className="container flex flex-col text-white xl:items-center lg:flex-row">
      <div className="flex-1 order-1 block xl:w-1/2 xl:order-2">
        <div className="relative">
          <h2 className="pt-12 text-6xl leading-none font-gilroyBold lg:text-right heading-huge">{data.heading}</h2>
          <h2 className="text-2xl leading-none whitespace-pre lg:text-right lg:text-6xl font-regular">{data.subheading ? `- ${data.subheading}` : ' '}</h2>
        </div>
      </div>
      <p className="justify-end flex-1 order-2 pt-4 lg:pt-0 lg:w-1/2 text-small lg:text-xl lg:order-1 font-gilroyBold">{data.content}</p>
    </header>
  </section>
);

export default Component;
