import React from 'react';
import SbEditable from 'storyblok-react';

const Component = ({ blok }) => (
  <SbEditable content={blok}>
    <div className="container py-4 lg:py-8 lg:pt-10">
      <div className="text-3xl font-regular">{blok.street_name}</div>
      <h4 className="text-3xl font-gilroyBold uppercase md:text-2xl xl:text-4xl font-regular text-light-green">{blok.street_numbers && blok.street_numbers.replace(/\s/g, '')}</h4>
    </div>
  </SbEditable>
);

export default Component;
