import React from 'react';
import SbEditable from 'storyblok-react';

const Teaser2 = ({ blok }) => (
  <SbEditable content={blok}>
    <>
      <header>
        <div className="relative pl-12 text-sm text-blue-700 uppercase font-semiBold lg:text-lg lg:pl-24 tagline-lg">{blok.category}</div>
        <h2 className="text-3xl leading-snug text-blue-700 font-semiBold lg:text-6xl lg:pt-2">{blok.heading}</h2>
        <h3 className="pb-2 text-3xl leading-none text-light lg:text-6xl">{blok.tagline}</h3>
      </header>
      <p className="flex items-center pb-2 pr-0 text-lg leading-relaxed lg:text-justify lg:pr-18 lg:py-8">{blok.content}</p>
    </>
  </SbEditable>
);

export default Teaser2;
