import { Menu } from "@headlessui/react"
import Link from "next/link"
import { HiChevronDown } from "react-icons/hi2"
import { useState, useEffect, useRef } from "react";
import classnames from "classnames"
const AnchorNav = ({anchorNavigation}) => {
    const ref = useRef(null)
    const timeoutRef = useRef(null)
    const [elementVisible, setElementVisible] = useState(true)
    const [topThreshold, setTopThreshold] = useState(0)
    

    useEffect(() => {
        const updateThreshold = () => {
            if (window.innerWidth < 768) {
                setTopThreshold(56)
            } else {
                setTopThreshold(0)
            }
        }

        const handleScroll = () => {
            if (!ref.current) {
                return
            }
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                const top = ref.current.getBoundingClientRect().top
                if (top <= topThreshold) {
                    setElementVisible(false)
                } else {
                    setElementVisible(true)
                }
            }, 100)
        }

        updateThreshold()
        window.addEventListener('resize', updateThreshold)
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('resize', updateThreshold)
            window.removeEventListener('scroll', handleScroll)
        }
    }, [topThreshold])

    
    if (!anchorNavigation) {
        return null
    }

    return (
        <>
            <div ref={ref}/>
            <nav className={classnames("p-4 top-14 z-10 w-full lg:hidden bg-white", elementVisible ? "static" : "fixed left-0 right-0")}>
                <Menu>
                    {({ open }) => (
                        <>
                            <Menu.Button className='relative flex items-center justify-between w-full py-4 text-sm leading-none font-gilroyMedium'>
                                {anchorNavigation.title}
                                <HiChevronDown className={open ? 'transform rotate-180' : ''}/>                    
                            </Menu.Button>
                            <Menu.Items as="ul" className='absolute left-0 right-0 z-10 px-4 py-4 pt-0 space-y-4 bg-white border-b  '>
                                {anchorNavigation.anchorLinks.map((link) => {
                                    if(link.hide) return
                                    return (
                                        <Menu.Item key={link._uid} as='li' className='text-sm leading-none underline font-regular'>                            
                                            <Link href={`/${link.url.cached_url}${link.url.anchor ? `#${link.url.anchor}` : ''}`}>
                                                <a className=''>
                                                    {link.name}
                                                </a>
                                            </Link>
                                        </Menu.Item>
                                )})}
                            </Menu.Items>
                        </>
                    )}
                </Menu>
            </nav>
            <nav
                className={classnames("top-0 z-50 hidden w-full bg-white border-b lg:block", elementVisible ? "static" : "fixed")}
                id="anchorNav"
            >
                <ul className="flex justify-center gap-15">
                    {anchorNavigation.anchorLinks.map((link) => {
                        if(link.hide) return
                        return(
                            <Link key={link._uid} href={`/${link.url.cached_url}${link.url.anchor ? `#${link.url.anchor}` : ''}`}>
                                <a className='py-8 underline font-regular'>
                                    {link.name}
                                </a>                        
                            </Link>
                    )})}
                </ul>
            </nav>
        </>
    )
}

export default AnchorNav