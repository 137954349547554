import Image from "next/image"

const { default: SbEditable } = require("storyblok-react")

const HeadingBgImage = ({blok}) => (
    <SbEditable content={blok}>
        <section className="relative max-w-screen-2xl mx-auto px-12 py-48 md:py-32">
            <blockquote className="relative z-10 font-semiBold text-white text-4xl md:text-6xl leading-none md:leading-tight text-center">
                {blok.line_1}<br/>
                {blok.line_2}
            </blockquote>
            <div className="absolute w-full h-full top-0 left-0">
                <Image
                    src={blok.image.filename}
                    alt={blok.image.alt}
                    layout="fill"
                    objectFit="cover"
                />
                <div className="absolute w-full h-full left-0 top-0 bg-blue-bg bg-opacity-80 "/>
            </div>
        </section>
    </SbEditable>
)

export default HeadingBgImage