import React from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';
import Component from '../index';

const Container = ({ blok }) => (
  <SbEditable content={blok}>
    <section className={cx('container grid grid-cols-1 lg:grid-cols-2', blok.className)}>
      {blok.columns.map((blk) => <Component blok={blk} key={blk._uid} />)}
    </section>
  </SbEditable>
);

export default Container;
