import { extractDimensionsFromFilename } from 'utils/img-helpers'
import RichTextRaw from './RichTextRaw'
import Image from 'next/image';
import SbEditable from 'storyblok-react';


const ImageTitleText = ({blok}) => {

    const {height: mobileHeight, width: mobileWidth} = extractDimensionsFromFilename(blok.image_mobile.filename);
    
    // Check if blok.image_desktop.filename exists before destructuring
    const { height: desktopHeight = null, width: desktopWidth = null } = blok.image_desktop?.filename 
        ? extractDimensionsFromFilename(blok.image_desktop.filename) 
        : { height: null, width: null };

    return (
        <SbEditable content={blok}>
            <div>
                <h2 className="font-semiBold text-blue-700 text-4xl leading-none lg:text-6xl mb-5 lg:mb-15 ">{blok.title}</h2>
                <figure>
                    <div className={!!blok.image_desktop.filename ? 'block lg:hidden' : ''}>
                        <Image
                            src={blok.image_mobile.filename}
                            alt={blok.image_mobile.alt}
                            width={mobileWidth}
                            height={mobileHeight}                        
                        />
                    </div>
                    {blok.image_desktop.filename && <div className="hidden lg:block">
                        <Image
                            src={blok.image_desktop.filename}
                            alt={blok.image_desktop.alt}
                            width={desktopWidth}
                            height={desktopHeight}                        
                        />
                    </div>}
                    <figcaption className='mt-11 lg:max-w-843'>
                        <RichTextRaw text={blok.text}/>
                    </figcaption>
                </figure>
            </div>
        </SbEditable>
    )
}

export default ImageTitleText