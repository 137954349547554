import Image from 'next/image';
import SbEditable from 'storyblok-react';
import { extractDimensionsFromFilename } from '../../utils/img-helpers';
import FaqItem from './FaqItem'
import { useState } from 'react';
import RichTextRaw from './RichTextRaw';
import ReactDOMServer from "react-dom/server";
import Head from 'next/head';

const FaqGrid = ({blok}) => {

  const {height, width} = extractDimensionsFromFilename(blok.image.filename)
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const structuredData = blok?.faq_items?.map((faq) => {
    return {
      "@type": "Question",
      "name": faq.title,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": ReactDOMServer.renderToStaticMarkup(
          <RichTextRaw text={faq.answer}/>
        ),
      },
    };
  });
  
  const ldScript = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": structuredData,
  };

  return (
    <>
      <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldScript) || "" }}
          />
      </Head>
      <SbEditable content={blok}>
        <section className="max-w-screen-2xl mx-auto bg-white grid grid-cols-1 md:grid-cols-2 py-8 lg:py-16 xl:py-40" id={blok.section_id}>        
          <div>
            <h4 className='px-8 font-semiBold text-blue-700 text-3xl lg:text-4.5xl leading-none lg:pr-12 xl:pr-20'>{blok.title}</h4>
            <div className='grid grid-cols-1 gap-y-6 px-8 py-8 lg:py-12 lg:pr-12 xl:pr-20'>
              {blok.faq_items.map((item, index) => (
                <FaqItem 
                  key={item._uid}
                  item={item}
                  isOpen={activeIndex === index}
                  onClick={() => handleItemClick(index)}
                />
              ))}
            </div>
          </div>
          <div className='px-8 pb-10 md:p-0'>
            <div className='relative w-full pb-full overflow-hidden md:pb-0 md:h-auto'>
              <div className="absolute top-0 left-0 w-full h-full object-cover md:hidden">
                <Image
                  src={blok.image.filename}
                  alt={blok.image.alt}
                  layout="fill"
                  objectFit="cover"
                  className="w-full h-full"
                />
              </div>
              <div className="hidden md:block">
                <Image
                  src={blok.image.filename}
                  alt={blok.image.alt}
                  layout="responsive"
                  width={width}
                  height={height}
                  className="w-auto h-auto"
                />
              </div>
            </div>
          </div>
        </section>
      </SbEditable>
    </>
  );
};

export default FaqGrid;
