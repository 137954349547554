import React, { useState } from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';
import Component from '../index';

const ArticleContent = ({ blok }) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <SbEditable content={blok}>
      <div className="flex flex-col lg:chart">
        <header>
          <h2 className="text-2xl leading-tight text-blue-700 font-gilroyBold">
            {blok.heading}
          </h2>
          <h3 className="pb-8 text-2xl leading-none font-gilroyBold">
            {blok.tagline}
          </h3>
        </header>
        <p className="flex-1 text-lg lg:text-justify">{blok.content}</p>
        <div>
          {blok.extended_content && (
            <button
              className="inline-flex items-center px-4 py-2 mt-8 text-lg text-white hover:bg-blue-200 bg-blue-700 focus:outline-none lg:mt-0"
              type="button"
              onClick={() => setExpanded(!isExpanded)}
            >
              <span>{isExpanded ? 'Weniger erfahren' : 'Mehr erfahren'}</span>
              <svg viewBox="0 0 64 64" className="inline-block w-10 pl-4">
                {isExpanded ? (
                  <path
                    className="fill-current"
                    d="M44,8.7l-9.1-7.7c-1.7-1.4-4.2-1.4-5.9,0L20,8.7c-0.6,0.5-0.7,1.5-0.2,2.1c0.5,0.6,1.5,0.7,2.1,0.2l8.6-7.2v58.7
	c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V3.8l8.6,7.2c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.9-0.2,1.1-0.5C44.8,10.2,44.7,9.2,44,8.7z"
                  />
                ) : (
                  <path
                    className="fill-current"
                    d="M62.9,29.1L55.3,20c-0.5-0.6-1.5-0.7-2.1-0.2c-0.6,0.5-0.7,1.5-0.2,2.1l7.2,8.6H1.5C0.7,30.5,0,31.2,0,32 c0,0.8,0.7,1.5,1.5,1.5h58.7L53,42.1c-0.5,0.6-0.5,1.6,0.2,2.1c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.9-0.2,1.1-0.5l7.7-9.1	C64.4,33.3,64.4,30.7,62.9,29.1z"
                  />
                )}
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="relative flex flex-col justify-center overflow-auto border-2 border-blue-200 rounded-md shadow-lg lg:p-1 lg:p-8 lg:justify-end md:col-span-2 chart">
        {blok.items?.map((blk, index) => (
          <Component blok={blk} key={blk._uid} idx={index} />
        ))}
      </div>
      <div
        className={cx('text-lg lg:col-span-3 row-start-2 lg:row-start-3', {
          'fade-in': isExpanded,
          hidden: !isExpanded,
        })}
      >
        {blok.extended_content}
      </div>
    </SbEditable>
  );
};

export default ArticleContent;
