import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import cx from "classnames";

const Button = React.forwardRef(({ classes, href, onClick, name }, ref) => {
  return (
    <a className={classes} href={href} onClick={onClick} ref={ref}>
      {name}
    </a>
  );
});

Button.displayName = "NavigationButton";

const MainNavigationLink = ({ className, name, slug, onClick }) => {
  const router = useRouter();
  const route = router.query.slug || router.route;
  const link = slug?.startsWith("/") ? slug : `/${slug}`;
  if (["/", "/startseite"].includes(link)) {
    return (
      <Link href={link} passHref>
        <Button classes={className} name={name} />
      </Link>
    );
  }

  const isActive = slug
    ? route.includes(slug.replace(/\/$/, ""))
    : route === "/";
  const classes = cx(className, { "is-active": isActive });

  return (
    <Link href={link} passHref>
      <Button classes={classes} name={name} />
    </Link>
  );
};

export default MainNavigationLink;
