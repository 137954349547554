import React from 'react';
import Carousel from 're-carousel';
import IndicatorDots from './indicator-dots';
import Buttons from './buttons';
import Component from '../index';

const CarouselComponent = ({ blok }) => (
  <div className="square2">
    <div>
      <Carousel loop widgets={[IndicatorDots, Buttons]} className={`${blok.className}`}>
        {blok.items?.map((blk) => (
          <Component blok={blk} key={blk._uid} />
        ))}
      </Carousel>
    </div>
  </div>
);

export default CarouselComponent;
