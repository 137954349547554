import React from 'react';
import SbEditable from 'storyblok-react';

const InlineTeaser = ({ blok }) => (
  <SbEditable content={blok}>
    {blok?.link?.cached_url ? (
      <a href={blok.link.cached_url} className="flex flex-col bg-gray-200 lg:flex-row">
        <Teaser blok={blok} />
      </a>
    ) : (
      <div className="flex flex-col bg-gray-200 lg:flex-row">
        <Teaser blok={blok} />
      </div>
    )}
  </SbEditable>
);

const Teaser = ({ blok }) => (
  <figure className="flex flex-col lg:flex-row">
    <img alt={blok.image.alt} src={blok.image.filename} />
    <figcaption className="flex flex-col py-6 pr-6">
      <div className="relative pl-10 ml-8 text-base leading-tight text-blue-700 uppercase lg:pl-12 lg:text-lg font-semiBold tagline">
        {blok.tagline}
      </div>
      <h2 className="py-2 ml-8 text-xxl blue-700 font-semiBold">
        {blok.heading}
      </h2>
      <p className="flex-1 pb-8 ml-8 text-sm leading-snug tracking-wider xl:pr-8 xl:pb-0">
        {blok.content}
      </p>
      <div className="bg-white">
        <p className="py-1 ml-8">{blok.metadata}</p>
      </div>
    </figcaption>
  </figure>
);

export default InlineTeaser;
