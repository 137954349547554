import React from 'react';
import SbEditable from 'storyblok-react';

const Component = ({ blok }) => (
  <SbEditable content={blok}>
    <div className="flex flex-col items-center p-4 lg:p-8 lg:pt-10">
      <h4 className="text-5xl font-gilroyBold uppercase font-semiBold text-light-green">{blok.value}</h4>
      <div className="text-3xl lg:pb-4 font-regular">{blok.description}</div>
    </div>
  </SbEditable>
);

export default Component;
