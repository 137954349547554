import React from 'react';

const styles = {
  wrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: '100',
    bottom: '50%',
    textAlign: 'center',
  },
  btn: {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'absolute',
    bottom: '0',
    font: '16px/30px sans-serif',
    color: 'rgba(255,255,255,0.8)',
  },
  left: {
    left: '0',
  },
  right: {
    right: '0',
  },
};

export default function Buttons(props) {
  const {
    index, total, loop, prevHandler, nextHandler,
  } = props;
  return (
    <div style={styles.wrapper} className="flex justify-between">
      { (loop || index !== 0) && (
        <button className="w-6 h-6 text-white focus:outline-none lg:ml-3" onClick={prevHandler} type="button" aria-label="Previous Carousel Item">
          <svg viewBox="0 0 612 612" className="fill-current">
            <path d="M477.2,604.5c10.1-10,10.1-26.2,0-36.2L189.4,306L477.2,43.7c10.1-10,10.1-26.2,0-36.2c-10.1-10-26.4-10-36.4,0 L134.6,286.6c-5.4,5.3-7.7,12.4-7.3,19.4c-0.4,7,1.9,14.1,7.3,19.4l306.2,279.1C450.9,614.5,467.2,614.5,477.2,604.5z" />
          </svg>
        </button>
      )}
      { (loop || index !== total - 1) && (
        <button className="w-6 h-6 text-white focus:outline-none lg:mr-3" onClick={nextHandler} type="button" aria-label="Next Carousel Item">
          <svg viewBox="0 0 612 612" className="fill-current">
            <path d="M134.8,7.5c-10.1,10-10.1,26.2,0,36.2L422.6,306L134.8,568.3c-10.1,10-10.1,26.2,0,36.2c10.1,10,26.4,10,36.4,0 l306.2-279.1c5.4-5.3,7.7-12.4,7.3-19.4c0.4-7-1.9-14.1-7.3-19.4L171.2,7.5C161.1-2.5,144.8-2.5,134.8,7.5z" />
          </svg>
        </button>
      )}
    </div>
  );
}
