import Image from "next/image"
import { extractDimensionsFromFilename } from "utils/img-helpers"

const CardPartner = ({blok}) => {
    const {width, height} = extractDimensionsFromFilename(blok.logo.filename)
    return (
        <div className="relative flex flex-col gap-3 text-gray-600 bg-white p-4">
            <div className="relative h-6">
                <Image
                    src={blok.logo.filename}
                    alt={blok.logo.alt}
                    layout="fill"
                    objectFit="contain"
                    objectPosition='left'
                />
            </div>
            <h3 className="font-semiBold text-black">{blok.title}</h3>
            {blok.line_1 &&<p>{blok.line_1}</p>}
            {blok.line_2 && <p>{blok.line_2}</p>}
            {blok.line_3 && <p>{blok.line_3}</p>}
        </div>
    )
}

export default CardPartner