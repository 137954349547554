import React from 'react';

const Placeholder = ({ componentName }) => (
  <div>
    The component:{' '}
    {componentName}
    {' '}
    has not been created yet.
  </div>
);

export default Placeholder;
