import React from 'react';
import { useMeasure } from 'react-use';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import SbEditable from 'storyblok-react';

export const App = ({ blok }) => {
  const [containerRef, { width: containerWidth }] = useMeasure();
  // The chart that we want to download the PNG for.
  const [chart, setChart] = React.useState();

  const chartData = blok.data?.tbody.map(item => ({
    name: item.body[0].value,
    value: item.body[1].value,
  }));

  return (
    <SbEditable content={blok}>
      <div className="text-blue-700">{blok.name}</div>
      <ResponsiveContainer>
        <LineChart
          ref={ref => setChart(ref)} // Save the ref of the chart
          data={chartData}
          height={400}
          width={400}
          margin={{
            top: 45,
            right: 30,
            left: 20,
            bottom: 15,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip label="Wert" />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#304C77"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="value" stroke="#304C77" />
        </LineChart>
      </ResponsiveContainer>
    </SbEditable>
  );
};

export default App;
