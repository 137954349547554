import { HiPlus, HiMinus } from "react-icons/hi";
import RichTextRaw from "./RichTextRaw";
import { useRef } from "react";
import classNames from "classnames";
import { NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer";

const FaqItem = ({ item, isOpen, onClick }) => {
 const contentHeight = useRef()
  return(
    <div className="w-full">
    <button 
      className={classNames("flex w-full justify-between items-center text-left border font-gilroyMedium text-sm lg:text-lg leading-4 lg:leading-5 p-4 lg:p-6 group", {
        'border-b-0': isOpen,
        'border-blue-700': isOpen
      })} 
      onClick={onClick}
    >
     {item.title}
     {!isOpen && <HiPlus className="text-orange-full text-2xl transform group-hover:rotate-90 transition-all"/>}
     {isOpen && <HiMinus className="text-orange-full text-2xl"/>}
    </button>
    <div 
      ref={contentHeight} 
      className={classNames("border-blue-700 overflow-hidden", {
        'border border-t-0': isOpen
      })} 
      style={
        isOpen
        ? { height: contentHeight.current.scrollHeight }
        : { height: "0px" }
      }
    >
      <RichTextRaw
        text={item.answer}
        nodeResolvers={{
          [NODE_PARAGRAPH]: (children) => (
            <p className="text-sm p-4 lg:p-6 pt-0 text-gray-500 leading-6 lg:text-lg lg:leading-7">{children}</p>
          )
        }}
      />
    </div>
   </div>
  )
}

export default FaqItem