import React from 'react';
import SbEditable from 'storyblok-react';

const AccordionContainer = ({ blok }) => (
  <SbEditable content={blok}>
    <section className="container grid grid-cols-1 pt-8 lg:grid-cols-8">
      <div className="col-span-1 bg-gray-200 lg:col-span-6">
        <div className="pt-10 mx-12 text-left lg:pt-0 lg:m-12">
          <h2 className="text-2xl leading-tight text-blue-700 xl:leading-none font-semiBold xl:text-6xl lg:text-4xl">
            {blok.heading}
          </h2>
          <p className="text-2xl font-light leading-tight xl:leading-none xl:text-6xl lg:text-4xl">
            {blok.subheading}
          </p>
        </div>
      </div>
      <div className="col-span-1 bg-gray-200 lg:col-span-2">
        <div className="m-12">
          <a
            className="flex justify-between px-5 py-2 text-lg text-white bg-blue-700 hover:bg-blue-200 lg:px-6 lg:py-3"
            href={blok.button_link?.cached_url}
          >
            <span>{blok.button_label}</span>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              viewBox="0 0 64 64"
              className="inline-block w-8 h-8 pl-4"
            >
              <path
                className="fill-current"
                d="M62.9,29.1L55.3,20c-0.5-0.6-1.5-0.7-2.1-0.2c-0.6,0.5-0.7,1.5-0.2,2.1l7.2,8.6H1.5C0.7,30.5,0,31.2,0,32 c0,0.8,0.7,1.5,1.5,1.5h58.7L53,42.1c-0.5,0.6-0.5,1.6,0.2,2.1c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.9-0.2,1.1-0.5l7.7-9.1	C64.4,33.3,64.4,30.7,62.9,29.1z"
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  </SbEditable>
);

export default AccordionContainer;
