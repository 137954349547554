import React from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';

const Component = ({ blok }) => (
  <SbEditable content={blok}>
    <div className="transform h-80 flip">
      <div className="relative flex w-full h-full align-top flip-inner">
        <div className="absolute w-full h-full bg-no-repeat bg-cover flip-front" style={{ backgroundImage: `url(${blok.image.filename})` }} />
        <div className={cx('flip-back absolute w-full h-full text-white p-6 lg:px-10 lg:pt-10 lg:pb-6 flex flex-col', {
          'bg-blue-200': blok.occupation === '1',
          'bg-light-green': blok.occupation === '2',
          'bg-light-olive-green': blok.occupation === '3',
          'bg-orange': blok.occupation === '4',
        })}
        >
          <p className="flex-1">{blok.quote}</p>
          <h3 className="font-semiBold">{blok.name}</h3>
          <p>{blok.role}</p>
          <a href={`mailto:${blok.email}`}><span className="pt-4"><small>{blok.email}</small></span></a>
        </div>
      </div>
    </div>
  </SbEditable>
);

export default Component;
