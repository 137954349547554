import React from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';

const TeaserWithHeading = ({ blok }) => (
  <SbEditable content={blok}>
    <header className={cx("teaser-heading", blok.className)}>
      <div className="relative pl-12 mt-8 text-base text-blue-700 uppercase font-semiBold lg:mt-0 lg:text-lg lg:pl-24 tagline-lg">{blok.category}</div>
      <h2 className="text-3xl leading-snug text-blue-700 font-semiBold lg:text-6xl">{blok.heading}</h2>
      <h3 className="text-3xl font-light leading-none lg:inline-block text-dark-grey lg:text-6xl">{blok.tagline}</h3>
    </header>
  </SbEditable>
);

export default TeaserWithHeading;
