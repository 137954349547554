import React, { useRef, useState } from "react";
import Link from "next/link";
import cx from "classnames";
import useOutsideClick from "../hooks/useOutsideClick";

const Dropdown = ({ navigation }) => {
  const wrapperRef = useRef(null);
  const [isActive, toggleDropdownVisibility] = useState(false);

  useOutsideClick(wrapperRef, toggleDropdownVisibility);

  return (
    <div ref={wrapperRef} className="flex">
      <button
        className="hidden pl-1 text-white lg:block lg:text-black focus:outline-none"
        type="button"
        aria-label="Expand menu"
      >
        <svg className="visible w-3 h-3 arrow-down" viewBox="0 0 612 612">
          <path
            className="fill-current"
            d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782 c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296 c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"
          />
        </svg>
      </button>
      <div
        className={cx(
          "hidden lg:block relative lg:absolute lg:bg-gray-100 shadow-xs dropdown-menu min-w-full w-auto right-0",
          {
            visible: isActive,
            invisible: !isActive,
            "opacity-100": isActive,
            "opacity-0": !isActive,
          }
        )}
      >
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          className="lg:bg-gray-100"
        >
          {navigation?.[0].navlinks
            .filter((item) => !item?.hide)
            .map((item) => {
              const { _uid, name, url: linkUrl, linktype } = item;
              let link = linkUrl?.url || linkUrl?.cached_url;
              link =
                link?.startsWith("/") || linktype === "url" ? link : `/${link}`;
              return (
                <Link key={_uid} href={link} passHref>
                  <a
                    className="block px-2 py-2 mx-4 my-2 text-lg leading-5 border-b border-gray-400 lg:text-gray-700 lg:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                    role="menuitem"
                  >
                    {name}
                  </a>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
