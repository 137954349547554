import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import '@splidejs/react-splide/css/core';
import Image from "next/image";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { NODE_HEADING, NODE_PARAGRAPH } from "storyblok-rich-text-react-renderer";
import RichTextRaw from "./RichTextRaw";
import classNames from "classnames";


const { default: SbEditable } = require("storyblok-react")

const Process = ({blok}) => {
    return (
        <SbEditable content={blok}>
            <div>
                <h2 className="text-4xl leading-none text-center text-blue-700 font-semiBold lg:text-6xl">{blok.title}</h2>
                <div className="mt-10 lg:mt-20">
                    <Splide
                        className="relative h-full mb-12 border-0 lg:mb-0 lg:px-20"
                        hasTrack={false}
                        options={{
                            perPage: 5,
                            gap: 0,
                            arrows: true,
                            classes: {
                                page: "splide__pagination__page h-2 w-2 rounded-full"
                            },
                            breakpoints: {
                                450: {
                                    perPage: 1
                                },
                                640: {
                                    perPage: 2,

                                },
                                1023: {
                                    perPage: 3,
                                    arrows: false,

                                },
                                1279: {
                                    perPage: 4,                                
                                }
                            }
                        }}
                        onPaginationUpdated={(_, list) => {
                            list.items.forEach((item) => {
                                if (item.button.classList.contains("is-active")) {
                                item.button.classList.add("bg-blue-700", "border-b-0");
                                } else {
                                item.button.classList.remove("bg-blue-700");
                                item.button.classList.add("bg-blue-200");
                                }
                            });
                        }}
                    >
                        <div className="absolute left-0 justify-between hidden w-full transform -translate-y-1/2 lg:flex splide__arrows top-1/2">
                            <button className="splide__arrow splide__arrow--prev bg-blue-50">
                                <HiChevronLeft className="text-5xl"/>
                            </button>
                            <button className="splide__arrow splide__arrow--next bg-blue-50">
                                <HiChevronRight className="text-5xl"/>
                            </button>
                        </div>
                        <SplideTrack className="h-full">
                        {blok.steps.map((step, index) => (
                            <SplideSlide
                            key={step._uid}
                            className="border-0"
                            >
                                <ProcessStep 
                                    step={step}
                                    index={index}
                                    stepsLength={blok.steps.length}
                                />
                            </SplideSlide>
                        ))}
                        </SplideTrack>
                        <ul className="flex items-center justify-center gap-3 mt-5 splide__pagination" />
                    </Splide>                  
                </div>
            </div>
        </SbEditable>
    )
}

export default Process

const ProcessStep = ({step, index, stepsLength}) => {

    return (
        <div className={classNames("flex flex-col h-full")}>
            <div className={classNames("bg-blue-700 h-full p-3 lg:py-4 flex flex-col justify-between mr-2 sm:mr-3 md:mr-5 lg:mr-8 xl:mr-10")}>
                <div className="relative h-16 lg:h-20">
                <Image
                    src={step.icon.filename}
                    alt={step.icon.alt}
                    layout="fill"
                    objectFit="contain"
                />
                </div>
                <RichTextRaw 
                    text={step.text}
                    nodeResolvers={{
                        [NODE_PARAGRAPH]: (children) => (
                            <p className="mt-3 text-xs leading-4 text-center text-white font-gilroyMedium lg:text-sm">{children}</p>
                        ),
                        [NODE_HEADING]: (children) => (
                            <h3 className="mt-5 text-sm leading-5 text-center text-white font-gilroyBold lg:text-base">{children}</h3>
                        ),
                    }}
                />
            </div>
            <div className={classNames("relative flex justify-center mt-5", { "mt-5 mr-2 sm:mr-3 md:mr-5 lg:mr-8 xl:mr-10" : index === stepsLength-1})}>
                <div className={classNames("absolute -top-3 h-0.5 w-6 bg-blue-700 transform rotate-90", {
                    '-translate-x-1 sm:-translate-x-1.5 md:-translate-x-2.5 lg:-translate-x-4 xl:-translate-x-5': index !== stepsLength-1
                })}/>
                <div className={classNames("absolute -left-2.75 top-1/2 h-0.5 w-6 bg-blue-700 transform rotate-90", {
                    'hidden' : index !== 0
                })}/>
                <div className={classNames("absolute -right-2.75 top-1/2 h-0.5 w-6 bg-blue-700 transform rotate-90", {
                    'hidden' : index !== stepsLength-1
                })}/>
                <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-blue-700"/>
                <div className={
                    classNames(
                        "relative z-10 flex justify-center items-center w-8 h-8 bg-blue-50 text-blue-700 font-gilroyBold text-base",
                        {
                            "-left-1 sm:-left-1.5 md:-left-2.5 lg:-left-4 xl:-left-5": index !== stepsLength-1
                        }
                    )}>{index+1}</div>
            </div>
        </div>
    )
}