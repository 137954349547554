import React from 'react';
import cx from 'classnames';
import SbEditable from 'storyblok-react';

const Image = ({ blok, onClick }) => (
  <SbEditable content={blok}>
    {onClick ? (
      <button className={cx('flex justify-center items-center', blok.className)} onClick={() => onClick(blok._uid)} type="button">
        <div style={{backgroundImage: `url(${blok.image.filename})`}} className="w-full bg-center bg-no-repeat bg-contain"/>
      </button>
    ) : (
      <div className={cx('flex justify-center items-center', blok.className)}>
        <div style={{backgroundImage: `url(${blok.image.filename})`}} className="w-full h-full bg-center bg-no-repeat bg-contain"/>
      </div>
    )}

  </SbEditable>
)

export default Image;
