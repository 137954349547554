import React from 'react';
import SbEditable from 'storyblok-react';

const Component = ({ blok }) => (
  <SbEditable content={blok}>
    <div className="flex flex-col items-center justify-center h-full p-16 align-center">
      <img alt={blok.image.alt} src={blok.image.filename} className="w-12 h-12 m-6 lg:w-32 lg:h-32" />
      <div className="text-center text-xl lg:text-2.5xl font-semiBold uppercase">{blok.heading}</div>
      <div className="font-light text-center text-regular lg:text-lg">{blok.tagline}</div>
    </div>
  </SbEditable>
);

export default Component;
