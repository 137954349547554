export const extractDimensionsFromFilename = (url) => {
  // Regular expression to match dimensions pattern in the URL
  const regex = /(\d+)x(\d+)/;
  const match = url.match(regex);

  if (match) {
    const [_, width, height] = match;
    return { width: parseInt(width, 10), height: parseInt(height, 10) };
  }

  // Return null or some default dimensions if pattern not found
  return { width: 0, height: 0 };
};