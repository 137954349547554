import React from 'react';
import SbEditable from 'storyblok-react';
import ReactMarkdown from 'react-markdown';
import Component from '../index';

const Teaser = ({ blok }) => (
  <SbEditable content={blok}>
    <section className="container pt-8 lg:pt-0 lg:pb-16">
      <header className="flex flex-col">
        <div className="relative pl-12 mt-4 text-base text-blue-700 uppercase lg:text-lg lg:pl-24 font-semiBold lg:mt-20 tagline-lg">
          {blok.backdrop}
        </div>
        <h2 className="text-3xl text-blue-700 font-semiBold lg:text-6xl">
          {blok.headline}
        </h2>
        <h3 className="pb-10 text-3xl text-light leading-extra-tight lg:text-6xl">
          {blok.tagline}
        </h3>
        <p className="flex-1 pb-12 pr-0 text-lg leading-relaxed lg:text-justify lg:pr-20 article">
          <ReactMarkdown linkTarget="_blank">{blok.content}</ReactMarkdown>
        </p>
        <div>
          {blok.button?.map((blk, index) => (
            <Component blok={blk} key={blk._uid} idx={index} />
          ))}
        </div>
      </header>
      <div className="flex justify-center lg:mt-16 lg:justify-end">
        {blok.imagebox?.map((blk, index) => (
          <Component blok={blk} key={blk._uid} idx={index} />
        ))}
      </div>
    </section>
  </SbEditable>
);

export default Teaser;
