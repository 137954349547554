import React, { useEffect, useState } from 'react';

import {
  PieChart,
  Pie,
  Label,
  Legend,
  Cell,
  ResponsiveContainer,
  Sector,
} from 'recharts';

const colors = [
  '#304c77',
  '#93c5e8',
  '#bfc722',
  '#88b138',
  '#a18463',
  '#f6de40',
  '#f6a940',
  '#f67840',
];

const renderActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
}) => (
  <g>
    <text x={cx} y={cy} dy={20} textAnchor="middle">
      <tspan fill="#2e384d" fontSize="50" fontWeight="bold">
        {payload.value}
      </tspan>
      <tspan
        dy={-25}
        dx={5}
        textAnchor="middle"
        fill="#2e384d"
        fontSize="16"
        fontWeight="bold"
      >
        %
      </tspan>
    </text>

    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
    <Sector
      cx={cx}
      cy={cy}
      startAngle={startAngle}
      endAngle={endAngle}
      innerRadius={innerRadius - 6}
      outerRadius={outerRadius + 6}
      fill={fill}
    />
  </g>
);

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () =>
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });

      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  return windowSize;
}

export const Component = ({ blok }) => {
  const [activeIndex1, setActiveIndex1] = useState(1);
  const [activeIndex2, setActiveIndex2] = useState(1);

  const size = useWindowSize();
  const isMobile = size.width < 1280;

  const onPie1Enter = (_, index) => {
    setActiveIndex1(index);
    setActiveIndex2(index);
  };

  const onPie2Enter = (_, index) => {
    setActiveIndex1(index);
    setActiveIndex2(index);
  };

  const pie1Data = blok.data?.tbody.map(item => ({
    name: item.body[0].value,
    value: Number(item.body[1].value),
  }));
  const pie2Data = blok.data.tbody.map(item => ({
    name: item.body[0].value,
    value: Number(item.body[2].value),
  }));

  const pie1Title = blok.data?.thead[1].value;
  const pie2Title = blok.data?.thead[2].value;

  const legend = pie1Data.map((item, index) => ({
    value: item.name,
    color: colors[index],
  }));

  const renderLegend = ({ payload, onMouseEnter, onClick }) => (
    <ul className="recharts-default-legend">
      {payload.map((entry, index) => (
        <li
          data-key={index}
          className={`recharts-legend-item legend-item-${index}`}
          key={`item-${index}`}
          style={{ lineHeight: '1.65' }}
          onMouseEnter={onMouseEnter}
          onClick={onClick}
          align="left"
        >
          <svg
            className="recharts-surface"
            width="14"
            height="14"
            viewBox="0 0 32 32"
            version="1.1"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginRight: '10px',
            }}
            data-key={index}
          >
            <path
              fill={payload[index].color}
              className="recharts-symbols"
              transform="translate(16, 16)"
              d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
            />
          </svg>
          <span
            className={`recharts-legend-item-text ${
              index === activeIndex1 && 'font-semiBold'
            }`}
            data-key={index}
          >
            {entry.value}
          </span>
        </li>
      ))}
    </ul>
  );

  const handleLegendMouseEnter = e => {
    setActiveIndex1(Number(e.target.dataset.key));
    setActiveIndex2(Number(e.target.dataset.key));
  };

  const handleLegendMouseClick = e => {
    setActiveIndex1(Number(e.target.dataset.key));
    setActiveIndex2(Number(e.target.dataset.key));
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <text
          x={isMobile ? '50%' : 150}
          y={isMobile ? 60 : 105}
          textAnchor="middle"
        >
          <tspan
            fill="#2e384d"
            fontSize="24"
            fontWeight="700"
            fontFamily="Gilroy-SemiBold"
          >
            {pie1Title}
          </tspan>
        </text>
        <text
          x={isMobile ? '50%' : 400}
          y={isMobile ? 360 : 105}
          textAnchor="middle"
        >
          <tspan
            fill="#2e384d"
            fontSize="24"
            fontWeight="700"
            fontFamily="Gilroy-SemiBold"
          >
            {pie2Title}
          </tspan>
        </text>
        <text
          x={isMobile ? '50%' : 160}
          y={isMobile ? '99%' : '85%'}
          textAnchor="middle"
        >
          <tspan
            fill="#2e384d"
            fontSize="14"
            fontWeight="400"
            fontFamily="Gilroy-Regular"
          >
            Quelle:{` `}
          </tspan>
          <tspan
            fill="#2e384d"
            fontSize="14"
            fontWeight="500"
            fontFamily="Gilroy-SemiBold"
          >
            {blok.datasource}
          </tspan>
        </text>
        <Pie
          activeIndex={activeIndex1}
          activeShape={renderActiveShape}
          cx={isMobile ? '50%' : 150}
          cy={isMobile ? 190 : 250}
          data={pie1Data}
          dataKey="value"
          innerRadius={83}
          isAnimationActive
          onMouseEnter={onPie1Enter}
          outerRadius={103}
        >
          {pie1Data.map((_, index) => (
            <Cell
              fill={colors[index]}
              key={`slice-${index}`}
              stroke={null}
              strokeWidth="3"
            />
          ))}
        </Pie>

        <Pie
          activeIndex={activeIndex2}
          activeShape={renderActiveShape}
          cx={isMobile ? '50%' : 400}
          cy={isMobile ? 480 : 250}
          data={pie2Data}
          dataKey="value"
          innerRadius={83}
          isAnimationActive
          onMouseEnter={onPie2Enter}
          outerRadius={103}
        >
          {pie2Data.map((_, index) => (
            <Cell
              fill={colors[index]}
              key={`slice-${index}`}
              stroke={null}
              strokeWidth="3"
            />
          ))}
        </Pie>

        <Legend
          align={isMobile ? 'center' : 'right'}
          content={renderLegend}
          iconType="circle"
          layout="vertical"
          payload={legend}
          paylodUniqBy
          verticalAlign={isMobile ? 'bottom' : 'middle'}
          onMouseEnter={handleLegendMouseEnter}
          onClick={handleLegendMouseClick}
          wrapperStyle={{
            width: !isMobile && '30%',
            paddingBottom: isMobile && '32px',
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Component;
