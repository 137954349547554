import React from 'react';
import parse from 'html-react-parser';
import SbEditable from 'storyblok-react';

const ContactDataBox = ({ blok }) => (
  <SbEditable content={blok}>
    <div className="p-6 transition duration-500 ease-in-out transform bg-white markdown-box hover:bg-blue-700 hover:text-white">
      <h4 className="pb-2 leading-relaxed uppercase font-semiBold">{blok.heading}</h4>
      <div className="pb-4 pr-10">{parse(blok.content)}</div>
      <h4 className="pb-2 leading-relaxed uppercase font-semiBold">{parse(blok.subheading)}</h4>
      <div className="pr-12">{parse(blok.additional_content)}</div>
    </div>
  </SbEditable>
);

export default ContactDataBox;
