import {
    MARK_BOLD,
  NODE_PARAGRAPH,
  render,
} from "storyblok-rich-text-react-renderer";

const RichTextRaw = ({
  text,
  markResolvers = {},
  nodeResolvers = {},
}) => {
  return (
    <>
      {render(text, {
        markResolvers: {
          [MARK_BOLD]: (children) => (
            <b className="font-bold">{children}</b>
          ),
          ...markResolvers,
        },
        nodeResolvers: {
          [NODE_PARAGRAPH]: (children) => (
            <p className="font-gilroyMedium text-blue-700 text-xl leading-6 lg:text-3.25xl lg:leading-10">{children}</p>
          ),
          ...nodeResolvers,
        },
      })}
    </>
  );
};

export default RichTextRaw