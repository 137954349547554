import React, { useState } from 'react';
import SbEditable from 'storyblok-react';

import VerticalSliderItem from './VerticalSliderItem';

const Container = ({ blok }) => {
  const [activeItem, setActiveItem] = useState(0);
  const itemsMax = blok.items?.length;
  return (
    <SbEditable content={blok}>
      <section
        className="relative w-full bg-black bg-opacity-50 bg-center bg-no-repeat bg-cover vertical-slider"
        style={{ backgroundImage: `url(${blok.items[activeItem].image?.filename}) ` }}
        id={blok.id}
      >
        <div className="container relative">
          {activeItem > 0 && (
          <div className="absolute right-0 z-10 px-8 py-2 text-2xl font-gilroyBold bg-white text-dark-grey top-14">
            <button className="focus:outline-none" onClick={() => setActiveItem(activeItem - 1)} type="button">
              {blok.items[activeItem - 1]?.heading}
              <svg viewBox="0 0 64 64" className="inline-block w-10 pl-4">
                <path
                  className="fill-current"
                  d="M44,8.7l-9.1-7.7c-1.7-1.4-4.2-1.4-5.9,0L20,8.7c-0.6,0.5-0.7,1.5-0.2,2.1c0.5,0.6,1.5,0.7,2.1,0.2l8.6-7.2v58.7	c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V3.8l8.6,7.2c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.9-0.2,1.1-0.5C44.8,10.2,44.7,9.2,44,8.7z	"
                />
              </svg>
            </button>
          </div>
          )}
          <VerticalSliderItem data={blok.items[activeItem]} />
          {activeItem < itemsMax - 1 && (
          <div className="absolute right-0 z-10 px-8 py-2 text-2xl font-gilroyBold bg-white hover:bg-blue-200 text-dark-grey bottom-14">
            <button className="focus:outline-none" type="button" onClick={() => setActiveItem(activeItem + 1)}>
              {blok.items[activeItem + 1]?.heading}
              <svg viewBox="0 0 64 64" className="inline-block w-10 pl-4">
                <path
                  className="fill-current"
                  d="M44.2,53.2c-0.5-0.6-1.5-0.7-2.1-0.2l-8.6,7.2V1.5C33.5,0.7,32.8,0,32,0c-0.8,0-1.5,0.7-1.5,1.5v58.7L21.9,53	c-0.6-0.5-1.6-0.5-2.1,0.2c-0.5,0.6-0.5,1.6,0.2,2.1l9.1,7.6C29.9,63.6,31,64,32,64c1,0,2.1-0.4,2.9-1.1l9.1-7.6	C44.7,54.8,44.8,53.8,44.2,53.2z"
                />
              </svg>
            </button>
          </div>
          )}
        </div>
      </section>
    </SbEditable>
  );
};

export default Container;
