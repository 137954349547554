import { useEffect, useState } from "react";
import { PageItem } from "../api/types/graphql";

import client from "../api/sdk/clients/apolloClient";
import { getSdk } from "../api/sdk/getSdk";

import StoryblokClient from "storyblok-js-client";

const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN,
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export const dataSdk = getSdk(client);

export const useStoryblok = (
  originalStory: PageItem,
  preview: boolean,
  locale?: string
): any => {

  const [story, setStory] = useState<any>(originalStory);

  const handleInput = (event, callback) => {
    callback(event);
  };

  const inputCallback = (event) => {
    setStory(event.story);
  };

  const handleEnterEditMode = async (event) => {
    if (!event.storyId) {
      return;
    }
    
    if (event.storyId && !story) {

      
      const page = await dataSdk.getPageItem(
        { slug: event.storyId },
      );

      const newStory = page

      setStory(newStory);
    }
  };

  const initEventListeners = () => {
    const { StoryblokBridge } = window as any;

    if (typeof StoryblokBridge !== 'undefined') {
      const storyblokInstance = new StoryblokBridge({
        resolveRelations: [],
        language: locale,
      });

      storyblokInstance.on(['change', 'published'], () => {
        console.log("publish of change event")
        location.reload();
      });
      storyblokInstance.on("input", (event) =>
        handleInput(event, inputCallback)
      );
      storyblokInstance.on("enterEditmode", handleEnterEditMode);
    }
  };

  function addBridge(callback) {
    const hasStoryblokBridgeScript = document.getElementById("storyblokBridge");

    if (!hasStoryblokBridgeScript) {
      const script = document.createElement("script");
      script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
      script.id = "storyblokBridge";
      document.body.appendChild(script);
      script.onload = () => {
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    if (preview) {
      addBridge(initEventListeners);      
    }
  }, []);

  useEffect(() => {
    !preview && setStory(originalStory);
  }, [originalStory, preview]);

  return story;
};

export default Storyblok;
