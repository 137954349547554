import React, { useState } from 'react';
import {
  DirectionsRenderer,
  InfoWindow,
  Map,
  Marker,
  GoogleApiWrapper,
} from 'google-maps-react';
import { apiKey } from '../../lib/gmaps';

const Component = ({ google, blok }) => {
  const DirectionsService = new google.maps.DirectionsService();
  const [directions, setDirections] = useState('');
  const [selectedPlace, setSelectedPlace] = useState();
  const [showingInfoWindow, setShowingInfoWindow] = useState();
  const [activeMarker, setActiveMarker] = useState();

  const formattedOrigin = { lat: 6.5244, lng: 3.3792 };
  const formattedDestination = { lat: Number(blok.lat), lng: Number(blok.lon) };
  DirectionsService.route(
    {
      destination: formattedDestination,
      origin: formattedOrigin,
      travelMode: google.maps.TravelMode.DRIVING,
    },
    (result, status) => {
      if (status === google.maps.DirectionsStatus.OK && result) {
        // setDirections(result);
      } else {
        console.error(`error fetching directions ${result}`);
      }
    }
  );

  const onMarkerClick = (props, marker, clickEvent) => {
    setActiveMarker(marker);
    setShowingInfoWindow(true);
    setSelectedPlace(props);
  };

  const onClose = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(true);
      setActiveMarker(null);
    }
  };

  const exampleMapStyles = [
    {
      elementType: 'geometry',
      featureType: 'poi',

      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      elementType: 'labels.text',
      featureType: 'poi',

      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      featureType: 'water',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ];

  return (
    <div className="relative h-full rounded-md shadow-2xl google-map">
      <Map
        google={google}
        zoom={16}
        options={{
          styles: exampleMapStyles,
        }}
        disableDefaultUI
        initialCenter={{
          lat: Number(blok.lat),
          lng: Number(blok.lon),
        }}
      >
        <Marker
          onClick={onMarkerClick}
          name="Baugenossenschaft Letten BGL"
          position={{
            lat: Number(blok.lat),
            lng: Number(blok.lon),
          }}
        />
        <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}
          onClose={onClose}
        >
          <div>
            <h4>{activeMarker?.name}</h4>
            <p>{blok.street || `Schaffhauserstrasse 68`}</p>
            <p>{blok.city || `8057 Zürich`}</p>
          </div>
        </InfoWindow>
        {directions && <DirectionsRenderer directions={directions} />}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey,
})(Component);
